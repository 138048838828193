import grapesjs from 'grapesjs';
import initComponents from './components';
import initUniversalComponents from './universal-components';
import careerumComponents from './components-careerum';
import loadBlocks from './blocks-careerum';


export default grapesjs.plugins.add('gjs-blocks-careerum4.0', (editor: any, opts: any = {}) => {
  const config = {
    category: 'Careerum 4',
    ...opts
  };

  const domc = editor.DomComponents;

  // Add components
  const addTypeFromClassName = initComponents(editor, config);
  addTypeFromClassName(careerumComponents);

  initUniversalComponents(editor, config);

  // Add blocks
  loadBlocks(editor, config);

  // Define new Trait type - Goals tabs
  const goalsTimeTabType = 'data-name';
  editor.TraitManager.addType(goalsTimeTabType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(goalsTimeTabType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[goalsTimeTabType] = newVal;
    }
  });

  const goalsTimeTabComponents = [
    'club40-time-item'
  ];
  let defaultAttributesGoalsTimeTab: any = {
    required: false
  };
  defaultAttributesGoalsTimeTab[goalsTimeTabType] = '';

  goalsTimeTabComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Offer ID',
              name: goalsTimeTabType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesGoalsTimeTab
        }
      }

    })
  });

  // Define new Trait type - Tariffs settings
  const tariffsType = 'data-price';
  editor.TraitManager.addType(tariffsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(tariffsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[tariffsType] = newVal;
    }
  });

  const tariffsComponents = [
    'club40-tariffs-item'
  ];
  let defaultAttributesTariffs: any = {
    required: false
  };
  defaultAttributesTariffs[tariffsType] = '';

  tariffsComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена 1 мес Р',
              name: 'data-price-1m-rub',
              type: 'text',
            },
            {
              label: 'Цена 3 мес Р',
              name: 'data-price-3m-rub',
              type: 'text',
            },
            {
              label: 'Цена 3 мес полн Р',
              name: 'data-fullprice-3m-rub',
              type: 'text',
            },
            {
              label: 'Цена 6 мес Р',
              name: 'data-price-6m-rub',
              type: 'text',
            },
            {
              label: 'Цена 6 мес полн Р',
              name: 'data-fullprice-6m-rub',
              type: 'text',
            },
            {
              label: 'Цена 12 мес Р',
              name: 'data-price-12m-rub',
              type: 'text',
            },
            {
              label: 'Цена 12 мес полн Р',
              name: 'data-fullprice-12m-rub',
              type: 'text',
            },
            {
              label: 'Цена 1 мес €',
              name: 'data-price-1m-eur',
              type: 'text',
            },
            {
              label: 'Цена 3 мес €',
              name: 'data-price-3m-eur',
              type: 'text',
            },
            {
              label: 'Цена 3 мес полн €',
              name: 'data-fullprice-3m-eur',
              type: 'text',
            },
            {
              label: 'Цена 6 мес €',
              name: 'data-price-6m-eur',
              type: 'text',
            },
            {
              label: 'Цена 6 мес полн €',
              name: 'data-fullprice-6m-eur',
              type: 'text',
            },
            {
              label: 'Цена 12 мес €',
              name: 'data-price-12m-eur',
              type: 'text',
            },
            {
              label: 'Цена 12 мес полн €',
              name: 'data-fullprice-12m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 1 мес P',
              name: 'data-offer-1m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 3 мес P',
              name: 'data-offer-3m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 6 мес P',
              name: 'data-offer-6m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 12 мес P',
              name: 'data-offer-12m-rub',
              type: 'text',
            },
            {
              label: 'Оффер 1 мес €',
              name: 'data-offer-1m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 3 мес €',
              name: 'data-offer-3m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 6 мес €',
              name: 'data-offer-6m-eur',
              type: 'text',
            },
            {
              label: 'Оффер 12 мес €',
              name: 'data-offer-12m-eur',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTariffs
        }
      }

    })
  });


  // Define new Trait type - getcourse group
  const getcourseGroupType = 'getcourse-group';
  editor.TraitManager.addType(getcourseGroupType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseGroupType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[getcourseGroupType] = newVal;
    }
  });

  // Define new Trait type - getcourse redirect URL
  const getcourseRedirectURLType = 'getcourse-redirect-url';
  editor.TraitManager.addType(getcourseRedirectURLType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseRedirectURLType, newVal);
      // save new value of redirect url to attributes
      this.target.attributes.attributes[getcourseRedirectURLType] = newVal;
    }
  });

  const careerRequestComponents = [
    'careerum-club40-goals',
    'careerum-club40-goals-tablet',
    'careerum-club40-goals-mobile',
    'careerum-club40-form',
    'careerum-club40-form-tablet',
    'careerum-club40-form-mobile',
    'careerum-club40-popup-subscribe'
  ];
  let defaultAttributes: any = {
    required: false
  };
  defaultAttributes[getcourseGroupType] = '';
  defaultAttributes[getcourseRedirectURLType] = '';

  careerRequestComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {

          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Группа в GetCourse для лидов',
              name: getcourseGroupType,
              type: getcourseGroupType,
              changeProp: 1,
            },
            {
              label: 'Редирект после добавления в группу GetCourse',
              name: getcourseRedirectURLType,
              type: getcourseRedirectURLType,
              changeProp: 1,
            },
            {
              label: 'Попап после добавления в группу (id)',
              name: 'data-popup-open',
              type: 'text',
            }
          ],
          attributes: defaultAttributes
        }
      }

    })
  });

});
