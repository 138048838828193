export default function (editor: any, opt: any = {}) {
    const blockManager = editor.BlockManager;
    const category = opt.category;

    blockManager.add('careerum-club40-main', {
        label: 'Club 4 main',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-main">
            <div class="careerum-container-desc">
                <div class="club40-main-header">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105236868-Y2Lsy-Vect.svg"
                        alt="img">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105245948-FJlOP-Vect.svg"
                        alt="img">
                    <h1>Поможем построить карьеру<br> по любви в любой точке мира</h1>
                    <p>Careerum — платформа карьерного развития, с помощью которой тысячи<br> профессионалов из 30+ стран
                        уже построили карьеру мечты</p>
                </div>
                <div class="club40-main-tales">
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не получаю офферов</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Застрял на одной должности</p>
                        </div>
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Был большой перерыв в работе</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105635645-LsSKT-Fram.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Думаю о смене профессии</p>
                        </div>
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105635645-LsSKT-Fram.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Регулярно выгораю</p>
                        </div>
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не могу найти работу за границей</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105635645-LsSKT-Fram.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Работаю без мотивации</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не растет зарплата</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105635645-LsSKT-Fram.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Кажется, что я не на своем месте</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723105590285-zZI0k-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не понимаю, туда ли движется моя карьера</p>
                        </div>
                    </div>
                </div>
                <div class="club40-main-link careerum-flex-cc">
                    <a href="#buy" class="btn-color-main-dark-orange">Изменить карьеру с Careerum</a>
                </div>
                <div class="club40-main-bottom">
                    <div class="careerum-flex-fsfs">
                        <div>
                            <p class="club40-main-bottom-header">12 000+</p>
                            <p class="club40-main-bottom-text">профессионалов построили карьеру мечты с нами</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">4 000+</p>
                            <p class="club40-main-bottom-text">офферов получили специалисты по всему миру с нашей помощью
                            </p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">в 1,5 раза</p>
                            <p class="club40-main-bottom-text">средний рост<br> зарплаты клиентов<br> Careerum</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">6 лет</p>
                            <p class="club40-main-bottom-text">помогаем<br> превратить работу<br> в удовольствие</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-main-tablet', {
        label: 'Club 4 main (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-main-tablet">
            <div class="careerum-container-tablet">
                <div class="club40-main-header">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466319646-RNR9A-Vect.svg"
                        alt="img">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466319220-qzqJU-Vect.svg"
                        alt="img">
                    <h2>Поможем построить<br> карьеру по любви в любой<br> точке мира</h2>
                    <p>Careerum — платформа карьерного развития, с помощью которой<br> тысячи профессионалов из 30+ стран
                        уже построили карьеру мечты</p>
                </div>
                <div class="club40-main-tales">
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не могу найти работу</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Застрял на одной должности</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Кажется, что я не на своем месте</p>
                        </div>
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Был большой перерыв в работе</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Думаю о смене профессии</p>
                        </div>
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Регулярно выгораю</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Работаю без мотивации</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не растет зарплата</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не понимаю, туда ли движется моя карьера</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не могу найти работу за границей</p>
                        </div>
                    </div>
                </div>
                <div class="club40-main-link careerum-flex-cc">
                    <a href="#buy" class="btn-color-main-dark-orange">Изменить карьеру с Careerum</a>
                </div>
                <div class="club40-main-bottom">
                    <div class="careerum-flex-fsfs">
                        <div>
                            <p class="club40-main-bottom-header">12 000+</p>
                            <p class="club40-main-bottom-text">профессионалов построили карьеру мечты с нами</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">4 000+</p>
                            <p class="club40-main-bottom-text">офферов получили специалисты по всему миру с нашей помощью
                            </p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">в 1,5 раза</p>
                            <p class="club40-main-bottom-text">средний рост<br> зарплаты клиентов<br> Careerum</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">6 лет</p>
                            <p class="club40-main-bottom-text">помогаем<br> превратить работу<br> в удовольствие</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-main-mobile', {
        label: 'Club 4 main (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-main-mobile">
            <div class="careerum-container-mobile">
                <div class="club40-main-header">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723538992140-HqVEb-Vect.svg"
                        alt="img">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723538992001-jumGG-Vect.svg"
                        alt="img">
                    <h2>Поможем построить<br> карьеру по любви в любой точке мира</h2>
                    <p>Careerum — платформа карьерного развития, с помощью которой тысячи профессионалов из 30+ стран
                        уже<br> построили карьеру мечты</p>
                </div>
                <div class="club40-main-tales">
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Не могу найти работу</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Застрял на одной должности</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-light">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466510057-IeBl2-Unio.svg"
                                alt="" class="club40-main-tales-item-img-cross">
                            <p>Был большой перерыв в работе</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Кажется, что я не на своем месте</p>
                        </div>
                    </div>
                    <div class="club40-main-tales-wrapper careerum-flex-fsfs">
                        <div class="club40-main-tales-item-dark">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Регулярно выгораю</p>
                        </div>
                        <div class="club40-main-tales-item-blue">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723466509751-QKY45-Grou.svg"
                                alt="" class="club40-main-tales-item-img-smile">
                            <p>Думаю о смене профессии</p>
                        </div>
                    </div>
                </div>
                <div class="club40-main-link careerum-flex-cc">
                    <a href="#buy" class="btn-color-main-dark-orange">Изменить карьеру с Careerum</a>
                </div>
                <div class="club40-main-bottom">
                    <div class="careerum-flex-fsfs">
                        <div>
                            <p class="club40-main-bottom-header">12 000+</p>
                            <p class="club40-main-bottom-text">профессионалов построили<br> карьеру мечты с нами</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">4 000+</p>
                            <p class="club40-main-bottom-text">офферов получили специалисты по всему миру с нашей помощью
                            </p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">в 1,5 раза</p>
                            <p class="club40-main-bottom-text">средний рост зарплаты<br> клиентов Careerum</p>
                        </div>
                        <div>
                            <p class="club40-main-bottom-header">6 лет</p>
                            <p class="club40-main-bottom-text">помогаем превратить работу<br> в удовольствие</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-about', {
        label: 'Club 4 about',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-about">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">Подписка Careerum — это</h2>
                <div class="club40-about-top careerum-flex-fsfs">
                    <div>
                        <div class="club40-about-label-blue">
                            <p>Careerum — это результаты</p>
                        </div>
                        <p class="club40-about-header-white">Команда сертифицированных карьерных коучей и экспертов</p>
                        <p class="club40-about-text-white">Проработайте свой кейс со специалистами <br>с международным
                            опытом
                            и достигните цели<br>в два раза быстрее</p>
                    </div>
                    <div>
                        <div class="careerum-flex-fsfs">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723109088799-unRFk-phot.png"
                                    alt="">
                            </div>
                            <div>
                                <p class="club40-about-coach-name">Софи Бессон</p>
                                <p class="club40-about-coach-position">Карьерный эксперт</p>
                                <p class="club40-about-coach-text">Добрый день! Оставила комментарии к резюме. Уже очень
                                    хорошо, но есть что исправить</p>
                            </div>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723109088402-yji01-phot.png"
                                    alt="">
                            </div>
                            <div>
                                <p class="club40-about-coach-name">Яна Хмельницкая</p>
                                <p class="club40-about-coach-position">Карьерный коуч</p>
                                <p class="club40-about-coach-text">Предлагаю сформулировать ваши сильные стороны и
                                    достижения, сможете опираться на них в разговоре о повышении</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-about-center careerum-flex-sbst">
                    <div>
                        <div class="club40-about-label-blue">
                            <p>Careerum — это практика</p>
                        </div>
                        <p class="club40-about-header-dark">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-about-text-dark">Разберетесь в самых актуальных карьерных<br> темах: от тенденций в
                            оформлении резюме<br> до развития нетворка в любой стране</p>
                    </div>
                    <div>
                        <div class="club40-about-label-white">
                            <p>Careerum — это поддержка</p>
                        </div>
                        <p class="club40-about-header-dark">Сильное сообщество</p>
                        <p class="club40-about-text-dark">Получите доступ к сообществу профессионалов<br> из 30+ стран, где
                            вы сможете завести полезные знакомства и найти рекомендации и поддержку<br> в достижении вашей
                            цели</p>
                    </div>
                </div>
                <div class="club40-about-bottom careerum-flex-fsfs">
                    <div>
                        <div class="club40-about-label-white">
                            <p>Careerum — это знания</p>
                        </div>
                        <p class="club40-about-header-white">80+ курсов от топовых экспертов для любой карьерной цели</p>
                        <p class="club40-about-text-white">Учитесь, когда и где удобно — материалы доступны<br> 24/7 на
                            любом
                            устройстве. Получайте целевые навыки, чтобы развивать карьеру так, как нужно вам</p>
    
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110551770-KJNwC-Grou.png"
                            alt="">
                    </div>
                </div>
                <div class="club40-about-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Выбрать тариф</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-about-tablet', {
        label: 'Club 4 about (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-about-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">Подписка Careerum — это</h2>
                <div class="club40-about-top careerum-flex-fsfs">
                    <div>
                        <p class="club40-about-header-white">Команда сертифицированных карьерных коучей и экспертов</p>
                        <p class="club40-about-text-white">Проработайте свою ситуацию со специалистами с международным
                            опытом и достигните цели в два раза быстрее</p>
                    </div>
                    <div>
                        <div>
                            <div class="careerum-flex-fsc">
                                <div class="club40-about-coach-photo">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468300463-EYtPX-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-about-coach-name">Софи Бессон</p>
                                    <p class="club40-about-coach-position">Карьерный эксперт</p>
                                </div>
                            </div>
                            <p class="club40-about-coach-text">Добрый день! Оставила комментарии к резюме. Уже очень
                                хорошо, но есть что исправить</p>
                        </div>
                        <div>
                            <div class="careerum-flex-fsc">
                                <div class="club40-about-coach-photo">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468300132-DXXeM-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-about-coach-name">Яна Хмельницкая</p>
                                    <p class="club40-about-coach-position">Карьерный коуч</p>
                                </div>
                            </div>
                            <p class="club40-about-coach-text">Предлагаю сформулировать достижения, сможете опираться в
                                обсуждении повышения</p>
                        </div>
                    </div>
                </div>
                <div class="club40-about-center careerum-flex-sbst">
                    <div>
                        <p class="club40-about-header-white">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-about-text-white">Разберетесь в самых актуальных карьерных темах: от тенденций
                            в оформлении резюме до развития нетворка в любой стране</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468833142-XOfcz-Grou.svg"
                            alt="">
                    </div>
                    <div>
                        <p class="club40-about-header-dark">Сильное сообщество профессионалов<br> со всего мира</p>
                        <p class="club40-about-text-dark">Получите доступ к сообществу профессионалов из 30+ стран, где
                            вы сможете построить нетворк и найти поддержку в достижении вашей цели</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468833762-HxGov-Play.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-about-bottom careerum-flex-fsfs">
                    <div>
                        <p class="club40-about-header-white">80+ курсов от топовых экспертов для любой цели</p>
                        <p class="club40-about-text-white">Учитесь, когда и где удобно — материалы доступны 24/7 на любом
                            устройстве. Получайте целевые навыки, чтобы развивать карьеру так, как нужно вам</p>
    
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468833453-Ork8u-Grou.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-about-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-about-mobile', {
        label: 'Club 4 about (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-about-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Подписка Careerum — это</h2>
                <div class="club40-about-top">
                    <div>
                        <p class="club40-about-header-white">Команда сертифицированных карьерных коучей и экспертов</p>
                        <p class="club40-about-text-white">Проработайте свою ситуацию со специалистами с международным
                            опытом и достигните цели в два раза быстрее</p>
                    </div>
                    <div>
                        <div>
                            <div class="careerum-flex-fsc">
                                <div class="club40-about-coach-photo">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468300463-EYtPX-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-about-coach-name">Софи Бессон</p>
                                    <p class="club40-about-coach-position">Карьерный эксперт</p>
                                </div>
                            </div>
                            <p class="club40-about-coach-text">Добрый день! Оставила комментарии к резюме. Уже очень
                                хорошо, но есть что исправить</p>
                        </div>
                        <div>
                            <div class="careerum-flex-fsc">
                                <div class="club40-about-coach-photo">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723468300132-DXXeM-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-about-coach-name">Яна Хмельницкая</p>
                                    <p class="club40-about-coach-position">Карьерный коуч</p>
                                </div>
                            </div>
                            <p class="club40-about-coach-text">Предлагаю сформулировать достижения, сможете опираться в
                                обсуждении повышения</p>
                        </div>
                    </div>
                </div>
                <div class="club40-about-center">
                    <div>
                        <p class="club40-about-header-white">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-about-text-white">Разберетесь в самых актуальных карьерных темах: от тенденций
                            в оформлении резюме до развития нетворка в любой стране</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723540946071-OF6ci-Grou.svg"
                            alt="">
                    </div>
                    <div>
                        <p class="club40-about-header-dark">Сильное сообщество профессионалов со всего мира</p>
                        <p class="club40-about-text-dark">Получите доступ к сообществу профессионалов из 30+ стран, где
                            вы сможете построить нетворк и найти поддержку в достижении вашей цели</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723541019685-hHvNE-Play.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-about-bottom">
                    <div>
                        <p class="club40-about-header-white">80+ курсов от топовых<br> экспертов для любой цели</p>
                        <p class="club40-about-text-white">Учитесь, когда и где удобно — материалы доступны 24/7 на любом
                            устройстве. Получайте целевые навыки, чтобы развивать карьеру так, как нужно вам</p>
    
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723541208766-qWvqi-Grou.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-about-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Выбрать тариф</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-goals', {
        label: 'Club 4 goals',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-goals">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">С Careerum любая карьерная задача<br> вам по плечу</h2>
                <div class="club40-goals-tales careerum-flex-fsfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Быстро найдете работу в компании, куда всегда хотелось</p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Получите оффер на своих условиях, а не тот, который дают</p>
                    </div>
                    <div class="club40-goals-tale-rotate-right">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Подготовитесь к интервью, чтобы общаться с рекрутерами без паники и на равных </p>
                    </div>
                </div>
                <div class="club40-goals-tales careerum-flex-fsfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Устроитесь на работу<br> за границей и не упадете в грейде </p>
                    </div>
                    <div class="club40-goals-tale-rotate-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Начнете расти в профессии<br> и регулярно получать повышение </p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Перестанете выгорать,<br> начнете получать удовольствие от работы</p>
                    </div>
                </div>
                <div class="club40-goals-tales careerum-flex-fsfs">
                    <div class="club40-goals-tale-rotate-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Найдете свое место<br> в карьере, если запутались<br> и не знаете, как работать дальше </p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Укрепите профессиональную самооценку и начнете<br> строить карьеру вокруг себя,<br> а не наоборот
                        </p>
                    </div>
                    <div class="club40-goals-tale-rotate-right">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723110817980-Y5ocA-Vect.svg"
                            alt="">
                        <p>Повысите свою конкурентоспособность<br> даже после долгого<br> перерыва в работе</p>
                    </div>
                </div>
                <div class="club40-goals-form careerum-flex-sbfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723113134344-GuPXa-Vect.svg"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723113138463-WJviy-Unio.svg"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723113144846-xYKAa-Grou.svg"
                            alt="">
                        <p>У вас другая цель? Тоже поможем</p>
                        <div>
                            <p>Оставьте заявку,</p>
                            <p>а мы погрузимся в вашу ситуацию, подберем решение, и карьерные перемены начнутся уже в этом
                                месяце</p>
                        </div>
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных<br> в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Оставить
                                    заявку</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-goals-tablet', {
        label: 'Club 4 goals (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-goals-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">С Careerum любая карьерная задача<br> вам по плечу</h2>
                <div class="club40-goals-tales careerum-flex-fsst">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Быстро найдете работу в компании, куда всегда хотелось</p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Получите оффер на своих условиях, а не тот, который дают</p>
                    </div>
                    <div class="club40-goals-tale-rotate-right">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Подготовитесь к интервью, чтобы общаться с рекрутерами без паники и на равных </p>
                    </div>
                </div>
                <div class="club40-goals-tales careerum-flex-fsst">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Устроитесь на работу<br> за границей и не упадете в грейде </p>
                    </div>
                    <div class="club40-goals-tale-rotate-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Начнете расти<br> в профессии и регулярно получать повышение </p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Перестанете выгорать,<br> начнете получать удовольствие от работы</p>
                    </div>
                </div>
                <div class="club40-goals-tales careerum-flex-fsst">
                    <div class="club40-goals-tale-rotate-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Найдете свое место<br> в карьере, если запутались и не знаете, как работать дальше </p>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Укрепите профессиональную самооценку и начнете строить карьеру вокруг себя,<br> а не наоборот
                        </p>
                    </div>
                    <div class="club40-goals-tale-rotate-right">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                            alt="">
                        <p>Повысите свою конкуренто-способность<br> даже после долгого<br> перерыва в работе</p>
                    </div>
                </div>
                <div class="club40-goals-form careerum-flex-sbfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469779584-93ZWs-Vect.svg"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469779436-0tSV3-Unio.svg"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469778866-02ZRM-Grou.svg"
                            alt="">
                        <p>У вас другая цель? Тоже поможем</p>
                        <div>
                            <p>Оставьте заявку,</p>
                            <p>а мы погрузимся в вашу ситуацию, подберем решение, и карьерные перемены начнутся уже в этом
                                месяце</p>
                        </div>
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Оставить
                                    заявку</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-goals-mobile', {
        label: 'Club 4 goals (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-goals-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">С Careerum любая карьерная задача вам по плечу</h2>
                <div class="club40-goals-tales club40-goals-tales-slider">
                    <div class="club40-goals-tale">
                        <p class="club40-goals-tale-header">Найти новую работу</p>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Подготовитесь к интервью, чтобы общаться с рекрутерами без паники</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Быстро найдете работу в компании, куда всегда хотелось, на своих условиях</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Устроитесь на работу за границей и не упадете в грейде</p>
                        </div>
                    </div>
                    <div class="club40-goals-tale">
                        <p class="club40-goals-tale-header">Вырасти в зарплате /<br> должности</p>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Укрепите профессиональную самооценку и начнете строить карьеру вокруг себя, а не наоборот</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Начнете расти и регулярно получать повышение</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Повысите конкуренто-способность даже после долгого перерыва в работе</p>
                        </div>
                    </div>
                    <div class="club40-goals-tale">
                        <p class="club40-goals-tale-header">Понять, чего хочется от карьеры</p>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Найдете свое место в карьере, если запутались и не знаете, как работать дальше </p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723469156104-t334r-Vect.svg"
                                alt="">
                            <p>Перестанете выгорать, начнете получать удовольствие от работы</p>
                        </div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723542067084-cmkXg-Vect.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-goals-form">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723543242506-jHune-Vect.svg"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723543242175-LPPbu-Grou.svg"
                            alt="">
                        <p>У вас другая цель?<br> Тоже поможем</p>
                        <div>
                            <p>Оставьте заявку,</p>
                            <p>а мы погрузимся в вашу ситуацию, подберем решение, и карьерные перемены начнутся уже в этом
                                месяце</p>
                        </div>
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Оставить
                                    заявку</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-clients', {
        label: 'Club 4 clients',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-clients">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">Наши клиенты построили карьеру<br> в ведущих компаниях мира</h2>
                <div class="club40-clients-companies careerum-flex-fsfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115122363-xyHbj-Tesl.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331031-myt4l-Amaz.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115330585-9MSlm-2560.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115333262-UAImR-Spot.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332401-FkZ64-Micr.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332749-mIfxs-Sky_.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332515-khZye-Phil.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332145-gqB7m-John.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331911-hjvbp-Goog.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115333142-EdGAC-Sony.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331641-UuXdl-Effe.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331526-uL5Yd-Disn.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332027-6Ren2-Inte.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115330911-6pK57-Airb.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331174-9BWPm-Amer.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332287-n7a8A-Lero.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331415-4gsDs-Bost.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115517876-iF9c6-Toyo.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115333370-HJSp7-Yand.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115332622-sudY9-Puma.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723115331287-VGekQ-Avit.svg"
                            alt="">
                    </div>
                    <div>
                        <p>4 000+ кейсов</p>
                    </div>
                </div>
                <div class="club40-clients-slider">
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422853-o2LIn-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Михайлов</p>
                                <p>Technical Transfer Lead, Австрия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер c релокацией в Австрию, увеличил доход в 3 раза</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116421826-n9oaA-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Александра Пинчукова</p>
                                <p>менеджер бизнес-процессов, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в штаб-квартиру Puma в начале 2023 года – после 3 месяцев поиска</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422118-nici9-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Бухаров</p>
                                <p>Supply Chain Forecast Analyst, Нидерланды</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер от Mars с релокацией в Нидерланды</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422236-vLdYI-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Карина Пысина</p>
                                <p>бизнес-аналитик, Россия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер бизнес-аналитика, выросла в зарплате на 100%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422371-2l7A2-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Тая Штоль</p>
                                <p>UX/UI-дизайнер, Испания</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в немецкую компанию. Увеличила доход в 6 раз, получила соцпакет</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422492-aq4aa-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Евгения Вольхина</p>
                                <p>Project Manager, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в Big4 в Москве и зарплату в 3,5 раза больше, потом – оффер в Германию
                                с ростом ещё на 40%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422621-VPn05-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Олег Федоткин</p>
                                <p>Head of Department, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер на head-позицию с ростом зарплаты на 30%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422737-07i7D-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Людмила Киселёва</p>
                                <p>Recruiter ll, США</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер ассистента в Microsoft, через год стала рекрутером в Transocean</p>
                        </div>
                    </div>
                </div>
                <div class="club40-clients-slider-nav careerum-flex-fec">
                    <div class="club40-clients-slider-nav-prev">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7304_21282)">
                                <g filter="url(#filter0_b_7304_21282)">
                                    <rect y="40" width="40" height="40" transform="rotate(-90 0 40)" fill="#FF9F00" />
                                </g>
                                <path d="M13.1905 19.9993L26.8105 19.9993" stroke="white" stroke-width="2.64201"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.4629 26.2453L13.1896 19.9986L19.4629 13.752" stroke="white"
                                    stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <filter id="filter0_b_7304_21282" x="-18.1166" y="-18.1166" width="76.2332" height="76.2332"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                    <feComposite in2="SourceAlpha" operator="in"
                                        result="effect1_backgroundBlur_7304_21282" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21282"
                                        result="shape" />
                                </filter>
                                <clipPath id="clip0_7304_21282">
                                    <rect y="40" width="40" height="40" rx="20" transform="rotate(-90 0 40)" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="club40-clients-slider-nav-next">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7304_21286)">
                                <g filter="url(#filter0_b_7304_21286)">
                                    <rect x="40" width="40" height="40" transform="rotate(90 40 0)" fill="#FF9F00" />
                                </g>
                                <path d="M26.8095 20.0007L13.1895 20.0007" stroke="white" stroke-width="2.64201"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M20.5371 13.7547L26.8104 20.0014L20.5371 26.248" stroke="white"
                                    stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <filter id="filter0_b_7304_21286" x="-18.1166" y="-18.1166" width="76.2332" height="76.2332"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                    <feComposite in2="SourceAlpha" operator="in"
                                        result="effect1_backgroundBlur_7304_21286" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21286"
                                        result="shape" />
                                </filter>
                                <clipPath id="clip0_7304_21286">
                                    <rect x="40" width="40" height="40" rx="20" transform="rotate(90 40 0)" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-clients-tablet', {
        label: 'Club 4 clients (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-clients-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">Наши клиенты построили карьеру<br> в ведущих компаниях мира</h2>
                <div class="club40-clients-companies careerum-flex-fsfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017456-aQU00-Effe.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471019742-wiFVW-Toyo.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471597031-41Sbz-Vect.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471016854-KC0mT-Amaz.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471018086-kFUjz-Micr.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471019251-VE7GM-Spot.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017945-P9kjI-John.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471746563-8Sd3H-Yand.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723472436347-BtDwx-Sky_L.webp"
                            width="52px" alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017603-hfj9T-Goog.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471019060-otJ5z-Sony.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723472605983-YIeVv-Mars.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471018378-FPG9d-Puma.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017277-KsPCq-Disn.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017765-3Pia8-Inte.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471018244-GkXf4-Phil.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723471017135-DO4xq-Bost.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723472803990-3lR8o-Avit.svg"
                            alt="">
                    </div>
                    <div>
                        <p>4 000+ кейсов</p>
                    </div>
                </div>
                <div class="club40-clients-slider">
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422853-o2LIn-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Михайлов</p>
                                <p>Technical Transfer Lead, Австрия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер c релокацией в Австрию, увеличил доход в 3 раза</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116421826-n9oaA-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Александра Пинчукова</p>
                                <p>менеджер, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в штаб-квартиру Puma в начале 2023 года – после 3 месяцев поиска</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422118-nici9-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Бухаров</p>
                                <p>Supply Chain Forecast Analyst, Нидерланды</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер от Mars с релокацией в Нидерланды</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422236-vLdYI-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Карина Пысина</p>
                                <p>бизнес-аналитик, Россия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер бизнес-аналитика, выросла в зарплате на 100%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422371-2l7A2-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Тая Штоль</p>
                                <p>UX/UI-дизайнер, Испания</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в немецкую компанию. Увеличила доход в 6 раз, получила соцпакет</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422492-aq4aa-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Евгения Вольхина</p>
                                <p>Project Manager, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в Big4 в Москве и зарплату в 3,5 раза больше, потом – оффер в Германию
                                с ростом ещё на 40%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422621-VPn05-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Олег Федоткин</p>
                                <p>Head of Department, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер на head-позицию с ростом зарплаты на 30%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422737-07i7D-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Людмила Киселёва</p>
                                <p>Recruiter ll, США</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер ассистента в Microsoft, через год стала рекрутером в Transocean</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-clients-mobile', {
        label: 'Club 4 clients (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-clients-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Наши клиенты построили карьеру в ведущих компаниях</h2>
                <div class="club40-clients-companies careerum-flex-fsfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544686294-cXWs4-Vect.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685487-4vULq-Micr.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544684088-HJKiJ-Amaz.svg"
                            alt="" style="margin-top: 3px;">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685276-ErtgD-John.svg"
                            alt="" style="margin-top: 1px;">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544684694-z20V7-g10..svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544686048-B1Bzn-Spot.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723472436347-BtDwx-Sky_L.webp"
                            width="46px" alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544684875-Kh6BE-Goog.svg"
                            alt="" style="margin-top: 2px;">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685014-IhhTc-Grou.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685624-XDgU1-path.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685896-mDyZM-Puma.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544684504-0d0Ri-Avit.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544686180-aw0aC-Vect.svg"
                            alt="" style="margin-top: 1px;">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685145-EgMYR-Inte.svg"
                            alt="">
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723544685756-brX6G-Phil.svg"
                            alt="">
                    </div>
                    <div>
                        <p>4 000+ кейсов</p>
                    </div>
                </div>
                <div class="club40-clients-slider">
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422853-o2LIn-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Михайлов</p>
                                <p>Technical Transfer Lead, Австрия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер c релокацией в Австрию, увеличил доход в 3 раза</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116421826-n9oaA-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Александра Пинчукова</p>
                                <p>менеджер, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в штаб-квартиру Puma в начале 2023 года – после 3 месяцев поиска</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422118-nici9-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Сергей Бухаров</p>
                                <p>Supply Chain Forecast Analyst, Нидерланды</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер от Mars с релокацией в Нидерланды</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422236-vLdYI-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Карина Пысина</p>
                                <p>бизнес-аналитик, Россия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер бизнес-аналитика, выросла в зарплате на 100%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422371-2l7A2-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Тая Штоль</p>
                                <p>UX/UI-дизайнер, Испания</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в немецкую компанию. Увеличила доход в 6 раз, получила соцпакет</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422492-aq4aa-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Евгения Вольхина</p>
                                <p>Project Manager, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер в Big4 в Москве и зарплату в 3,5 раза больше, потом – оффер в Германию
                                с ростом ещё на 40%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422621-VPn05-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Олег Федоткин</p>
                                <p>Head of Department, Германия</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получил оффер на head-позицию с ростом зарплаты на 30%</p>
                        </div>
                    </div>
                    <div class="club40-clients-slider-item">
                        <div>
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116422737-07i7D-Grou.png"
                                    alt="">
                            </div>
                            <div>
                                <p>Людмила Киселёва</p>
                                <p>Recruiter ll, США</p>
                            </div>
                        </div>
                        <div>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723116002501-D55Pe-Righ.svg"
                                alt="">
                            <p>Получила оффер ассистента в Microsoft, через год стала рекрутером в Transocean</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-mission', {
        label: 'Club 4 mission',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-mission">
            <div class="careerum-container-desc">
                <div class="club40-mission-top careerum-flex-fsfs">
                    <div class="club40-mission-top-left">
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383095-IJaUt-Circ.svg"
                                alt="">
                            <p>Преображаем карьеры с 2018 года</p>
                        </div>
                        <p>Точно знаем, как стать профессионалом<br>
                            с сильными карьерными навыками, который</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383633-2vYzb-Vect.svg"
                            alt="">
                    </div>
                    <div class="club40-mission-top-right">
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383503-qQBVD-Unio.svg"
                                alt="">
                            <p>Знает, что востребовано на рынке</p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383365-pL8gq-Fram.svg"
                                alt="">
                            <p>Развивает навыки, за которые платят</p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383503-qQBVD-Unio.svg"
                                alt="">
                            <p>Заранее выстраивает стратегию повышения</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383365-pL8gq-Fram.svg"
                                alt="">
                            <p>Умеет выгодно преподносить свой опыт<br> в разных рабочих контекстах</p>
                        </div>
                        <div>
                            <p>
                                <span>Наша миссия:</span> сделать так, чтобы специалистов, осознанно управляющих своей
                                карьерой, стало как можно бо-о-о-о-ольше
                            </p>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118442385-bgk8K-Grou.svg"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="club40-mission-bottom">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118616351-b8BSw-Vect.svg" alt="">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118616229-fdPp2-Vect.svg" alt="">
                    <p>12 000+ профессионалов по всему миру<br> уже построили карьеру по своим правилам</p>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118683984-d1y11-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684271-AH5B0-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684395-sR8Vx-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684516-5Tr5C-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684634-7DOXr-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684760-uObOr-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684896-6sxpG-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118685030-I5nvh-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118685170-r30Nz-Grou.png"
                            alt="">
                    </div>
                </div>
                <div class="club40-mission-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-mission-tablet', {
        label: 'Club 4 mission (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-mission-tablet">
            <div class="careerum-container-tablet">
                <div class="club40-mission-top">
                    <div class="club40-mission-top-left">
                        <p>Знаем, как стать профессионалом с сильными карьерными навыками</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723473791743-ewVBv-Vect.svg"
                            alt="">
                    </div>
                    <div class="club40-mission-top-right">
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383503-qQBVD-Unio.svg"
                                alt="">
                            <p>Знает, что востребовано на рынке</p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383365-pL8gq-Fram.svg"
                                alt="">
                            <p>Развивает навыки, за которые платят</p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383503-qQBVD-Unio.svg"
                                alt="">
                            <p>Заранее выстраивает стратегию повышения</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118383365-pL8gq-Fram.svg"
                                alt="">
                            <p>Умеет выгодно преподносить свой опыт<br> в разных рабочих контекстах</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <p>Наша миссия:</p>
                            <p>сделать так, чтобы специалистов, осознанно управляющих своей карьерой, стало как можно больше
                            </p>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723474308924-2J0Zv-Grou.svg"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="club40-mission-label careerum-flex-fsc">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723474401911-XhK0o-Circ.svg" alt="">
                    <p>Преображаем карьеры с 2018 года</p>
                </div>
                <div class="club40-mission-bottom">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118616351-b8BSw-Vect.svg" alt="">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723474635030-6fs3R-Vect.svg" alt="">
                    <p>12 000+ профессионалов по всему миру<br> уже построили карьеру по своим правилам</p>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118683984-d1y11-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684271-AH5B0-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684395-sR8Vx-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684516-5Tr5C-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684634-7DOXr-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684760-uObOr-Grou.png"
                            alt="">
                    </div>
                </div>
                <div class="club40-mission-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-mission-mobile', {
        label: 'Club 4 mission (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-mission-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Точно знаем, как стать профессионалом с сильными карьерными
                    навыками</h2>
                <div class="club40-mission-top">
                    <div class="club40-mission-top-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520322-rmyPK-Vect.svg"
                            alt="">
                        <p>Специалист с такими навыками</p>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Осознанно управляет карьерой</p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Получает лучшие офферы </p>
                        </div>
                        <div class="careerum-flex-fsc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Быстро растет в должности и зарплате</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Знает, за какие скилы платят, и развивает их</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Заранее продумывает стратегию повышения</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545520992-Wp7M7-Vect.svg"
                                alt="">
                            <p>Использует свой опыт как инструмент достижения целей</p>
                        </div>
                    </div>
                    <div class="club40-mission-top-right">
                        <div>
                            <p>Наша миссия:</p>
                            <p>сделать так, чтобы специалистов, осознанно управляющих своей карьерой, стало как можно
                                бо-о-о-о-ольше</p>
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723545971958-ZuJE4-Grou.svg"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="club40-mission-label careerum-flex-fsc">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723474401911-XhK0o-Circ.svg" alt="">
                    <p>Преображаем карьеры с 2018 года</p>
                </div>
                <div class="club40-mission-bottom">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723546515249-YCFow-Vect.svg" alt="">
                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723546514877-ZE7qK-Vect.svg" alt="">
                    <p>12 000+ профессионалов по всему миру уже построили карьеру<br> по своим правилам</p>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118683984-d1y11-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684271-AH5B0-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684395-sR8Vx-Grou.png"
                            alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723118684516-5Tr5C-Grou.png"
                            alt="">
                    </div>
                </div>
                <div class="club40-mission-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-author', {
        label: 'Club 4 author',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-author">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">Ольга Лермонтова, фаундер Careerum</h2>
                <div class="club40-author-top careerum-flex-fsc">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121261660-K1WuM-Fram.png"
                            alt="">
                    </div>
                    <div>
                        <p>
                            «За 15 лет своей карьеры я прошла путь от репетитора английского языка до топ-менеджера в
                            Google,
                            Intel<br> и Microsoft.</p>
                        <p>
                            Я училась на своих ошибках, часто не знала, как правильно строить карьеру и сделать выбор,
                            который будет подходить именно мне, а не совпадать с навязанными шаблонами.</p>
                        <p>
                            Этот опыт я собрала в Careerum, чтобы другие профессионалы могли сэкономить время, избежать
                            фатальных карьерных ошибок, расти быстрее<br> и зарабатывать на том, что нравится именно им!»
                        </p>
                    </div>
                </div>
                <div class="club40-author-bottom">
                    <div class="careerum-flex-fsst">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827701-lK3KT-User.svg"
                                alt="">
                            <p>Помогла 12 000+ клиентам из России, СНГ, Европы, Северной и Южной Америки построить карьеру
                            </p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827589-RDqmT-Grad.svg"
                                alt="">
                            <p>Сертифицированный карьерный коуч International Coaching Federation</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827589-RDqmT-Grad.svg"
                                alt="">
                            <p>Степень MBA от бизнес-школы INSEAD, учится в Гарварде</p>
                        </div>
                    </div>
                    <div class="careerum-flex-fsst">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827344-JJ1IL-Brie.svg"
                                alt="">
                            <p>15+ лет успешной карьеры на руководящих позициях в 5 странах в tech-компаниях (Google,
                                Microsoft, Intel)</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827344-JJ1IL-Brie.svg"
                                alt="">
                            <p>Управляла бизнесом на 20+ рынках<br> Африки, Западной и Восточной Европы</p>
                        </div>
                    </div>
                </div>
                <div class="club40-author-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-author-tablet', {
        label: 'Club 4 author (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-author-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">Ольга Лермонтова, фаундер Careerum</h2>
                <div class="club40-author-top careerum-flex-fsc">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475008025-kxKl4-Fram.png"
                            alt="">
                    </div>
                    <div>
                        <p>
                            «За 15 лет своей карьеры я прошла путь<br> от репетитора английского языка до топ-<br>менеджера
                            в
                            Google,
                            Intel и Microsoft.</p>
                        <p>
                            Я училась на своих ошибках, часто не знала, как правильно строить карьеру и сделать выбор,
                            который будет подходить именно мне, а не совпадать с навязанными шаблонами.</p>
                        <p>
                            Этот опыт я собрала в Careerum, чтобы другие профессионалы могли сэкономить время, избежать
                            фатальных карьерных ошибок, расти быстрее и зарабатывать на том, что нравится именно им!»
                        </p>
                    </div>
                </div>
                <div class="club40-author-bottom">
                    <div class="careerum-flex-fsst">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827701-lK3KT-User.svg"
                                alt="">
                            <p>Помогла 12 000+ клиентам из России, СНГ, Европы, Северной и Южной Америки построить карьеру
                            </p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827344-JJ1IL-Brie.svg"
                                alt="">
                            <p>15+ лет успешной карьеры на руководящих позициях в 5 странах в tech-компаниях (Google,
                                Microsoft, Intel)</p>
                        </div>
                    </div>
                    <div class="careerum-flex-fsst">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827589-RDqmT-Grad.svg"
                                alt="">
                            <p>Сертифицированный карьерный коуч International Coaching Federation</p>
                        </div>
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723121827589-RDqmT-Grad.svg"
                                alt="">
                            <p>Степень MBA от бизнес-школы INSEAD, учится в Гарварде</p>
                        </div>
                    </div>
                </div>
                <div class="club40-author-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Оформить подписку</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-author-mobile', {
        label: 'Club 4 author (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-author-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Ольга Лермонтова, фаундер Careerum</h2>
                <div class="club40-author-top">
                    <div class="careerum-flex-cc">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723546777111-Sr73P-Fram.png"
                            alt="">
                    </div>
                    <div>
                        <p>«За 15 лет карьеры я прошла путь от репетитора английского языка до топ-менеджера в Google, Intel
                            и Microsoft.</p>
                        <p>Я училась на своих ошибках, часто не знала, как правильно строить карьеру.</p>
                        <p>Весь опыт я собрала в Careerum, чтобы другие профессионалы могли сэкономить время, избежать
                            фатальных карьерных ошибок, расти быстрее и зарабатывать на том, что нравится именно им!»</p>
                    </div>
                </div>
                <div class="club40-author-bottom">
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547194995-HHAge-User.svg"
                            alt="">
                        <p>Помогла 12 000+ клиентам из России, СНГ, Европы, Северной и Южной Америки построить карьеру </p>
                    </div>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547194808-hdZMf-Grad.svg"
                            alt="">
                        <p>Сертифицированный карьерный коуч ICF</p>
                    </div>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547194808-hdZMf-Grad.svg"
                            alt="">
                        <p>Степень MBA от бизнес-школы INSEAD, учится в Гарварде</p>
                    </div>
                    <div class="careerum-flex-fsfs">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547194495-04rIA-Brie.svg"
                            alt="">
                        <p>15+ лет успешной карьеры на руководящих позициях в 5 странах в Google, Microsoft, Intel</p>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-time', {
        label: 'Club 4 time',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-time">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">Сколько времени потребуется, чтобы</h2>
                <div class="club40-time-nav">
                    <ul class="club40-time-nav-wrapper careerum-flex-fsfs">
                    </ul>
                </div>
                <div class="club40-time-items">
                    <div class="club40-time-item active" data-name="Понять, чего хочется от карьеры">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123276318-o6rOi-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: иногда — всю жизнь</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Строите карьеру «как получится» и без плана</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не понимаете свои сильные стороны, сомневаетесь в себе</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Чувствуете, что в работе нужно что-то менять, но не знаете, что</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Работа перестала радовать<br> и превратилась в рутину</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знаете, что делать дальше: с карьерой и жизнью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Ощущаете себя на чужом месте</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330340-wzGhN-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330090-aI7Rw-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: от 4 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1–2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Определите свои «Хочу» и «Могу»</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, что мешает вам достичь карьерных целей
                                        <li>Выявите свои сильные стороны и таланты</li>
                                        <li>Определите свои ключевые ценности</li>
                                        <li>Поймете, что в работе радует, а что демотивирует</li>
                                        <li>Сформулируете свои профессиональные интересы</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Поймете свои «Надо»</p>
                                    </div>
                                    <ul>
                                        <li>Соотнесете свои таланты с запросами рынка</li>
                                        <li>Подружите свои ценности с бизнес-задачами</li>
                                        <li>Научитесь выбирать работу осознанно, а не брать, «что дают»</li>
                                        <li>Поставите долгосрочную карьерную цель с опорой на свои ценности</li>
                                        <li>Определите пути развития под свою долгосрочную цель</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4+ месяцa</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>В зависимости от целей:</p>
                                    </div>
                                    <ul>
                                        <li>Начнете осознанный поиск работы</li>
                                        <li>Составите план развития на текущем месте</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Научитесь работать без выгорания</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Распутать карьерный клубок не сложно, если
                                        рядом сертифицированные карьерные коучи и эксперты</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723125224907-7Ysdj-Unio.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover1">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723125004478-Uqcgc-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в своей стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123276318-o6rOi-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: 9–12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Долго и безуспешно ищете работу</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Ваше резюме не читают или игнорируют</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Нервничаете на интервью и не знаете, как себя вести</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую зарплату запросить — адекватно</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Боитесь откликаться на «крутые» вакансии</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330340-wzGhN-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330090-aI7Rw-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Создадите стратегию поиска</p>
                                    </div>
                                    <ul>
                                        <li>Определите цель поиска</li>
                                        <li>Составите стратегию и воронку поиска</li>
                                        <li>Подготовите продающее резюме</li>
                                        <li>Отправите первые отклики</li>
                                        <li>Напишете сильные сопроводительные письма</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2-3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Проработаете LinkedIn-профиль</li>
                                        <li>Адаптируете резюме и письма под вакансии</li>
                                        <li>Подготовитесь к собеседованиям</li>
                                        <li>Получите первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4–6 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете легко проходить интервью</li>
                                        <li>Научитесь вести переговоры об условиях работы</li>
                                        <li>Начнете получать первые офферы</li>
                                        <li>Будете выбирать офферы под свою цель и стратегию</li>
                                        <li>Построите нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как пройти испытательный срок</li>
                                        <li>Сформируете план развития на новом месте</li>
                                        <li>Узнаете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилы для роста</li>
                                        <li>Адаптируетесь в новой команде</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide careerum-flex-sbfs">
                                    <p class="club40-time-item-bottom-item-text">Найти новую работу можно в 2 раза быстрее,
                                        когда рядом сертифицированные карьерные коучи и эксперты с международным опытом</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723126169139-JLbtf-Grou.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover3">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723126405309-EBRwq-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в другой стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123276318-o6rOi-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: от 12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую странувыбрать</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знакомы с форматом иностранных интервью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Опасаетесь, что ваш доходстанет меньше</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, какие вакансии вам подойдут</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Боитесь конкуренции с местными кандидатами</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330340-wzGhN-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330090-aI7Rw-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1–2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Подготовитесь к поиску</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как устроен процесс найма за рубежом</li>
                                        <li>Определите географию, цель и стратегию поиска</li>
                                        <li>Составите резюме для выбранной страны</li>
                                        <li>Проработаете LinkedIn-профиль и сопроводительное письмо</li>
                                        <li>Начнете отправлять первые отклики</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3-5 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь проходить интервью на другом языке</li>
                                        <li>Поймете, как строить нетворк в нужной стране</li>
                                        <li>Сможете вести переговоры о зарплате и условиях</li>
                                        <li>Начнете получать первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6–9 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете проходить собеседования без стресса</li>
                                        <li>Научитесь легко общаться с иностранными рекрутерами</li>
                                        <li>Получите первые офферы и выберете из них подходящие именно под вашу цель</li>
                                        <li>Разовьете нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">9+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Узнаете, как успешно пройти испытательный срок</li>
                                        <li>Сформулируете план развития на новом месте</li>
                                        <li>Поймете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилс для роста</li>
                                        <li>Адаптируетесь к иностранной команде</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide careerum-flex-sbfs">
                                    <p class="club40-time-item-bottom-item-text">Получить оффер за границе можно быстрее и
                                        без стресса, когда рядом сертифицированные карьерные эксперты с международным опытом
                                    </p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723126555192-8CJQ6-Grou.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover4">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723126405309-EBRwq-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Вырасти в зарплате и должности">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123276318-o6rOi-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: до-о-о-о-олго</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знаете, как вырасти в зарплате и должности</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, как аргументировать повышение</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не знаете, сколько должен получать специалист как вы</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Ждете, что вас повысят, но этого не происходит</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не представляете, как может развиваться ваша карьера</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123372961-wd5eW-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, зачем демонстрировать результаты</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330340-wzGhN-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123330090-aI7Rw-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: от 3 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Подготовите базу для роста</p>
                                    </div>
                                    <ul>
                                        <li>Определите, что мешает вам расти в карьере
                                        <li>Определите с руководителем цели, чтобы развиваться в профессии</li>
                                        <li>Составите стратегию роста с учетом ваших суперсил и задач бизнеса</li>
                                        <li>Поймете: сколько стоят ваши навыки на рынке</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Вырастите в доходе / должности</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь строить коммуникации и брать стратегически важные проекты</li>
                                        <li>Поймете, как запрашивать и принимать фидбек</li>
                                        <li>Сможете заявлять о себе и демонстрировать результаты</li>
                                        <li>Научитесь вести переговоры о повышении в свою пользу</li>
                                        <li>Получите повышение в зарплате / должности</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsc">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723123375562-bETkB-Vect.svg"
                                            alt="">
                                        <p>Поставите новые рекорды</p>
                                    </div>
                                    <ul>
                                        <li>Определите дополнительные способы роста</li>
                                        <li>Составите стратегию дальнейшего повышения</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Сможете работать без выгорания</li>
                                        <li>Разовьете софтскил, необходимые для роста</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Расти в карьере можно регулярно, <br>если
                                        рядом сертифицированные карьерные коучи и эксперты с международным опытом</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723125950683-T4bEF-Unio.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover2">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723125004478-Uqcgc-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-time-tablet', {
        label: 'Club 4 time (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-time-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">Сколько времени потребуется, чтобы</h2>
                <div class="club40-time-nav">
                    <ul class="club40-time-nav-wrapper careerum-flex-fsfs">
                    </ul>
                </div>
                <div class="club40-time-items">
                    <div class="club40-time-item active" data-name="Понять, чего хочется от карьеры">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623488-GgpEV-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: иногда — всю жизнь</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Строите карьеру «как получится» и без плана</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете свои сильные стороны, сомневаетесь в себе</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Работа перестала радовать<br> и превратилась в рутину</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, что делать дальше: с карьерой и жизнью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Чувствуете, что в работе нужно что-то менять, но не знаете, что</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ощущаете себя на чужом месте</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475959505-4Gr3I-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623753-JoaXH-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: от 4 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1–2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Определите свои «Хочу» и «Могу»</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, что мешает вам достичь карьерных целей
                                        <li>Выявите свои сильные стороны и таланты</li>
                                        <li>Определите свои ключевые ценности</li>
                                        <li>Поймете, что в работе радует, а что демотивирует</li>
                                        <li>Сформулируете свои профессиональные интересы</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Поймете свои «Надо»</p>
                                    </div>
                                    <ul>
                                        <li>Соотнесете свои таланты с запросами рынка</li>
                                        <li>Подружите свои ценности с бизнес-задачами</li>
                                        <li>Научитесь выбирать работу осознанно, а не брать, «что дают»</li>
                                        <li>Поставите долгосрочную карьерную цель с опорой на свои ценности</li>
                                        <li>Определите пути развития под свою долгосрочную цель</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4+ месяцa</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>В зависимости от целей:</p>
                                    </div>
                                    <ul>
                                        <li>Начнете осознанный поиск работы</li>
                                        <li>Составите план развития на текущем месте</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Научитесь работать без выгорания</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Распутать карьерный клубок<br> не сложно,
                                        если
                                        рядом карьерные коучи и эксперты</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476494104-dIToE-Unio.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover1">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476607650-AQUXg-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в своей стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623488-GgpEV-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: 9–12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Долго и безуспешно ищете работу</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ваше резюме не читают или игнорируют</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Нервничаете на интервью и не знаете, как себя вести</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую зарплату запросить — адекватно</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Боитесь откликаться на «крутые» вакансии</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475959505-4Gr3I-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623753-JoaXH-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Создадите стратегию поиска</p>
                                    </div>
                                    <ul>
                                        <li>Определите цель поиска</li>
                                        <li>Составите стратегию и воронку поиска</li>
                                        <li>Подготовите продающее резюме</li>
                                        <li>Отправите первые отклики</li>
                                        <li>Напишете сильные сопроводительные письма</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2-3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Проработаете LinkedIn-профиль</li>
                                        <li>Адаптируете резюме и письма под вакансии</li>
                                        <li>Подготовитесь к собеседованиям</li>
                                        <li>Получите первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4–6 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете легко проходить интервью</li>
                                        <li>Научитесь вести переговоры об условиях работы</li>
                                        <li>Начнете получать первые офферы</li>
                                        <li>Будете выбирать офферы под свою цель и стратегию</li>
                                        <li>Построите нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как пройти испытательный срок</li>
                                        <li>Сформируете план развития на новом месте</li>
                                        <li>Узнаете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилы для роста</li>
                                        <li>Адаптируетесь в новой команде</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide">
                                    <p class="club40-time-item-bottom-item-text">Найти новую работу можно в 2 раза быстрее,
                                        когда рядом сертифицированные карьерные коучи и эксперты<br> с международным опытом
                                    </p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723477015660-EqFgL-Grou.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover3">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476976190-qRyft-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в другой стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623488-GgpEV-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: от 12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую странувыбрать</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знакомы с форматом иностранных интервью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Опасаетесь, что ваш доходстанет меньше</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, какие вакансии вам подойдут</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Боитесь конкуренции с местными кандидатами</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475959505-4Gr3I-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623753-JoaXH-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1–2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Подготовитесь к поиску</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как устроен процесс найма за рубежом</li>
                                        <li>Определите географию, цель и стратегию поиска</li>
                                        <li>Составите резюме для выбранной страны</li>
                                        <li>Проработаете LinkedIn-профиль и сопроводительное письмо</li>
                                        <li>Начнете отправлять первые отклики</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3-5 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь проходить интервью на другом языке</li>
                                        <li>Поймете, как строить нетворк в нужной стране</li>
                                        <li>Сможете вести переговоры о зарплате и условиях</li>
                                        <li>Начнете получать первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6–9 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете проходить собеседования без стресса</li>
                                        <li>Научитесь легко общаться с иностранными рекрутерами</li>
                                        <li>Получите первые офферы и выберете из них подходящие именно под вашу цель</li>
                                        <li>Разовьете нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">9+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Узнаете, как успешно пройти испытательный срок</li>
                                        <li>Сформулируете план развития на новом месте</li>
                                        <li>Поймете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилс для роста</li>
                                        <li>Адаптируетесь к иностранной команде</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide">
                                    <p class="club40-time-item-bottom-item-text">Получить оффер за границе можно быстрее и
                                        без стресса,<br> когда рядом сертифицированные карьерные эксперты<br> с
                                        международным опытом
                                    </p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723477015660-EqFgL-Grou.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover4">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476976190-qRyft-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Вырасти в зарплате и должности">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623488-GgpEV-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку: до-о-о-о-олго</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, как вырасти в зарплате и должности</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, как аргументировать повышение</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, сколько должен получать специалист как вы</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ждете, что вас повысят, но этого не происходит</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не представляете, как может развиваться ваша карьера</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, зачем демонстрировать результаты</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475959505-4Gr3I-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475623753-JoaXH-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum: от 3 месяцев</p>
                            <div class="club40-time-item-bottom-items careerum-flex-fsst">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Подготовите базу для роста</p>
                                    </div>
                                    <ul>
                                        <li>Определите, что мешает вам расти в карьере
                                        <li>Определите с руководителем цели, чтобы развиваться в профессии</li>
                                        <li>Составите стратегию роста с учетом ваших суперсил и задач бизнеса</li>
                                        <li>Поймете: сколько стоят ваши навыки на рынке</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Вырастите в доходе / должности</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь строить коммуникации и брать стратегически важные проекты</li>
                                        <li>Поймете, как запрашивать и принимать фидбек</li>
                                        <li>Сможете заявлять о себе и демонстрировать результаты</li>
                                        <li>Научитесь вести переговоры о повышении в свою пользу</li>
                                        <li>Получите повышение в зарплате / должности</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Поставите новые рекорды</p>
                                    </div>
                                    <ul>
                                        <li>Определите дополнительные способы роста</li>
                                        <li>Составите стратегию дальнейшего повышения</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Сможете работать без выгорания</li>
                                        <li>Разовьете софтскил, необходимые для роста</li>
                                    </ul>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Расти в карьере можно регулярно, <br>если
                                        рядом сертифицированные карьерные коучи и эксперты с международным опытом</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476749956-7beZF-Unio.svg"
                                        alt="" class="club40-time-item-bottom-item-img-hover2">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476607650-AQUXg-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-time-mobile', {
        label: 'Club 4 time (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-time-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Сколько времени потребуется, чтобы</h2>
                <div class="club40-time-nav">
                    <ul class="club40-time-nav-wrapper careerum-flex-fsfs">
                    </ul>
                </div>
                <div class="club40-time-items">
                    <div class="club40-time-item active" data-name="Понять, чего хочется от карьеры">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678415-BM0qX-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку:<br> иногда — всю жизнь</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Строите карьеру «как получится» и без плана</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Работа перестала радовать<br> и превратилась в рутину</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете свои сильные стороны, сомневаетесь в себе</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, что делать дальше: с карьерой и жизнью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Чувствуете, что в работе нужно что-то менять, но не знаете, что</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ощущаете себя на чужом месте</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547709640-SXy9e-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678785-eQSTq-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum:<br> от 4 месяцев</p>
                            <div class="club40-time-item-bottom-items">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 – 2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Определите свои «Хочу» и «Могу»</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, что мешает вам достичь карьерных целей</li>
                                    </ul>
                                    <ul>
                                        <li>Выявите свои сильные стороны и таланты</li>
                                        <li>Определите свои ключевые ценности</li>
                                        <li>Поймете, что в работе радует, а что демотивирует</li>
                                        <li>Сформулируете свои профессиональные интересы</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Поймете свои «Надо»</p>
                                    </div>
                                    <ul>
                                        <li>Соотнесете свои таланты с запросами рынка</li>
                                    </ul>
                                    <ul>
    
                                        <li>Подружите свои ценности с бизнес-задачами</li>
                                        <li>Научитесь выбирать работу осознанно, а не брать, «что дают»</li>
                                        <li>Поставите долгосрочную карьерную цель с опорой на свои ценности</li>
                                        <li>Определите пути развития под свою долгосрочную цель</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4+ месяцa</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>В зависимости от целей:</p>
                                    </div>
                                    <ul>
                                        <li>Начнете осознанный поиск работы</li>
                                    </ul>
                                    <ul>
    
                                        <li>Составите план развития на текущем месте</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Научитесь работать без выгорания</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Распутать карьерный клубок<br> не сложно,
                                        если
                                        рядом карьерные коучи и эксперты</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723550900705-RSDHW-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в своей стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678415-BM0qX-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку:<br> 9–12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Долго и безуспешно ищете работу</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ваше резюме не читают или игнорируют</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Нервничаете на интервью и не знаете, как себя вести</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую зарплату запросить — адекватно</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Боитесь откликаться на «крутые» вакансии</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547709640-SXy9e-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678785-eQSTq-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum:<br> 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Создадите стратегию поиска</p>
                                    </div>
                                    <ul>
                                        <li>Определите цель поиска</li>
                                    </ul>
                                    <ul>
    
                                        <li>Составите стратегию и воронку поиска</li>
                                        <li>Подготовите продающее резюме</li>
                                        <li>Отправите первые отклики</li>
                                        <li>Напишете сильные сопроводительные письма</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2-3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Проработаете LinkedIn-профиль</li>
                                    </ul>
                                    <ul>
    
                                        <li>Адаптируете резюме и письма под вакансии</li>
                                        <li>Подготовитесь к собеседованиям</li>
                                        <li>Получите первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">4–6 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете легко проходить интервью</li>
                                    </ul>
                                    <ul>
    
                                        <li>Научитесь вести переговоры об условиях работы</li>
                                        <li>Начнете получать первые офферы</li>
                                        <li>Будете выбирать офферы под свою цель и стратегию</li>
                                        <li>Построите нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как пройти испытательный срок</li>
                                    </ul>
                                    <ul>
    
                                        <li>Сформируете план развития на новом месте</li>
                                        <li>Узнаете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилы для роста</li>
                                        <li>Адаптируетесь в новой команде</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide">
                                    <p class="club40-time-item-bottom-item-text">Найти новую работу можно в 2 раза быстрее,
                                        когда рядом карьерные коучи и эксперты с международным опытом</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723550900705-RSDHW-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Найти работу в другой стране">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678415-BM0qX-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку:<br> от 12 месяцев</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, какую странувыбрать</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знакомы с форматом иностранных интервью</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Опасаетесь, что ваш доходстанет меньше</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, какие вакансии вам подойдут</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Боитесь конкуренции с местными кандидатами</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Получаете отказы и не понимаете, почему</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547709640-SXy9e-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678785-eQSTq-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum:<br> 3–6 месяцев</p>
                            <div class="club40-time-item-bottom-items">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1–2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Подготовитесь к поиску</p>
                                    </div>
                                    <ul>
                                        <li>Поймете, как устроен процесс найма за рубежом</li>
                                    </ul>
                                    <ul>
    
                                        <li>Определите географию, цель и стратегию поиска</li>
                                        <li>Составите резюме для выбранной страны</li>
                                        <li>Проработаете LinkedIn-профиль и сопроводительное письмо</li>
                                        <li>Начнете отправлять первые отклики</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3-5 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Выйдете на первые интервью</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь проходить интервью на другом языке</li>
                                    </ul>
                                    <ul>
    
                                        <li>Поймете, как строить нетворк в нужной стране</li>
                                        <li>Сможете вести переговоры о зарплате и условиях</li>
                                        <li>Начнете получать первые приглашения на интервью</li>
                                        <li>Скорректируете стратегию поиска с экспертом</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">6–9 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Начнете получать офферы</p>
                                    </div>
                                    <ul>
                                        <li>Начнете проходить собеседования без стресса</li>
                                    </ul>
                                    <ul>
    
                                        <li>Научитесь легко общаться с иностранными рекрутерами</li>
                                        <li>Получите первые офферы и выберете из них подходящие именно под вашу цель</li>
                                        <li>Разовьете нетворк и начнете получать больше приглашений</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">9+ месяцев</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Освоитесь на новой работе</p>
                                    </div>
                                    <ul>
                                        <li>Узнаете, как успешно пройти испытательный срок</li>
                                    </ul>
                                    <ul>
    
                                        <li>Сформулируете план развития на новом месте</li>
                                        <li>Поймете, как вырасти в зарплате и должности</li>
                                        <li>Разовьете софтскилс для роста</li>
                                        <li>Адаптируетесь к иностранной команде</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item-photo-wide">
                                    <p class="club40-time-item-bottom-item-text">Получить оффер за границе можно быстрее и
                                        без стресса, когда рядом сертифицированные карьерные эксперты с международным опытом
                                    </p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723550900705-RSDHW-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-time-item" data-name="Вырасти в зарплате и должности">
                        <div class="club40-time-item-top">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678415-BM0qX-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">В одиночку:<br> до-о-о-о-олго</p>
                            <div class="club40-time-item-top-items careerum-flex-fsfs">
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, как вырасти в зарплате и должности</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, как аргументировать повышение</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не знаете, сколько должен получать специалист как вы</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Ждете, что вас повысят, но этого не происходит</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не представляете, как может развиваться ваша карьера</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723475709158-wvSRC-Unio.svg"
                                        alt="">
                                    <p>Не понимаете, зачем демонстрировать результаты</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-time-item-bottom">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547709640-SXy9e-Vect.svg"
                                alt="">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723547678785-eQSTq-Grou.svg"
                                alt="">
                            <p class="club40-time-item-header">С Careerum:<br> от 3 месяцев</p>
                            <div class="club40-time-item-bottom-items">
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">1 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Подготовите базу для роста</p>
                                    </div>
                                    <ul>
                                        <li>Определите, что мешает вам расти в карьере</li>
                                    </ul>
                                    <ul>
                                        <li>Определите с руководителем цели, чтобы развиваться в профессии</li>
                                        <li>Составите стратегию роста с учетом ваших суперсил и задач бизнеса</li>
                                        <li>Поймете: сколько стоят ваши навыки на рынке</li>
                                        <li>Укрепите профессиональную самооценку</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">2 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Вырастите в доходе / должности</p>
                                    </div>
                                    <ul>
                                        <li>Научитесь строить коммуникации и брать стратегически важные проекты</li>
                                    </ul>
                                    <ul>
    
                                        <li>Поймете, как запрашивать и принимать фидбек</li>
                                        <li>Сможете заявлять о себе и демонстрировать результаты</li>
                                        <li>Научитесь вести переговоры о повышении в свою пользу</li>
                                        <li>Получите повышение в зарплате / должности</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item">
                                    <p class="club40-time-item-bottom-item-label">3 месяц</p>
                                    <div class="club40-time-item-bottom-item-header careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723476189609-z6cAb-Vect.svg"
                                            alt="">
                                        <p>Поставите новые рекорды</p>
                                    </div>
                                    <ul>
                                        <li>Определите дополнительные способы роста</li>
                                    </ul>
                                    <ul>
    
                                        <li>Составите стратегию дальнейшего повышения</li>
                                        <li>Наладите work-life balance</li>
                                        <li>Сможете работать без выгорания</li>
                                        <li>Разовьете софтскил, необходимые для роста</li>
                                    </ul>
                                    <div class="club40-time-item-bottom-item-switcher careerum-flex-fsc">
                                        <p>Показать больше</p>
                                        <p>Свернуть</p>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="black" stroke-width="1.5" stroke-miterlimit="10" />
                                            <path d="M15.375 10.875L12 14.625L8.625 10.875" stroke="black"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="club40-time-item-bottom-item-photo careerum-flex-fsfs">
                                    <p class="club40-time-item-bottom-item-text">Расти в карьере можно регулярно, если рядом
                                        сертифицированные карьерные коучи и эксперты с международным опытом</p>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723550900705-RSDHW-ÑÐº.png"
                                        alt="" class="club40-time-item-bottom-item-img-coaches">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-tariffs', {
        label: 'Club 4 tariffs',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-tariffs">
            <div class="careerum-container-desc">
                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278008892-Gajaw-Rect.png" alt="">
                <h2 class="careerum-club40-header">Оформите подписку Careerum </h2>
                <div class="club40-tariffs-nav careerum-flex-sbfs">
                    <div class="club40-tariffs-period">
                        <ul class="careerum-flex-fsfs">
                            <li data-period="12" class="active">12 месяцев</li>
                            <li data-period="6">6 месяцев</li>
                            <li data-period="3">3 месяца</li>
                            <li data-period="1">1 месяц</li>
                        </ul>
                    </div>
                    <div class="club40-tariffs-curr careerum-flex-fsst">
                        <ul class="careerum-flex-fsfs">
                            <li data-currency="eur" class="active">EUR</li>
                            <li data-currency="rub">RUB</li>
                        </ul>
                        <div class="club40-tariffs-notif careerum-flex-cc">
                            <div class="club40-tariffs-notif-icon">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723134336723-hWN6k-Warn.svg"
                                    alt="">
                            </div>
                            <div class="club40-tariffs-notif-content">
                                <p>Оплата в российских рублях возможна только картой российского банка. В евро — картой
                                    зарубежного банка</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-items careerum-flex-fsst">
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="12 900 ₽"
                        data-price-3m-rub="9 967 ₽" data-fullprice-3m-rub="29 900 ₽" data-price-6m-rub="6 900 ₽"
                        data-fullprice-6m-rub="47 400 ₽" data-price-12m-rub="5 900 ₽" data-fullprice-12m-rub="77 880 ₽"
                        data-price-1m-eur="149 €" data-price-3m-eur="120 €" data-fullprice-3m-eur="360 €"
                        data-price-6m-eur="90 €" data-fullprice-6m-eur="540 €" data-price-12m-eur="80 €"
                        data-fullprice-12m-eur="960 €" data-offer-1m-rub="#1mr1" data-offer-3m-rub="#3mr1"
                        data-offer-6m-rub="#6mr1" data-offer-12m-rub="#12mr1" data-offer-1m-eur="#1me1"
                        data-offer-3m-eur="#3me1" data-offer-6m-eur="#6me1" data-offer-12m-eur="#12me1">
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">Самостоятельный</p>
                        <p class="club40-tariffs-item-description">Если есть время, чтобы самому проработать свой карьерный
                            запрос<br> с помощью наших программ</p>
                        <div class="club40-tariffs-item-divider"></div>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723135867345-NpjXQ-Play.svg"
                                    alt="">
                                <p>18 онлайн-программ,<br> закрывающих любой карьерный запрос</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="careerum-flex-fsfs club40-tariffs-item-wrapper-price">
                                <div>
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div>
                                    <p>/</p>
                                </div>
                                <div>
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#thanks-no" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="14 900 ₽"
                        data-price-3m-rub="11 633 ₽" data-fullprice-3m-rub="34 900 ₽" data-price-6m-rub="9 817 ₽"
                        data-fullprice-6m-rub="58 900 ₽" data-price-12m-rub="8 000 ₽" data-fullprice-12m-rub="96 000 ₽"
                        data-price-1m-eur="199 €" data-price-3m-eur="170 €" data-fullprice-3m-eur="510 €"
                        data-price-6m-eur="130 €" data-fullprice-6m-eur="780 €" data-price-12m-eur="100 €"
                        data-fullprice-12m-eur="1 200 €" data-offer-1m-rub="#1mr" data-offer-3m-rub="#3mr"
                        data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr" data-offer-1m-eur="#1me"
                        data-offer-3m-eur="#3me" data-offer-6m-eur="#6me" data-offer-12m-eur="#12me">
                        <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">С поддержкой</p>
                        <p class="club40-tariffs-item-description">Если важна обратная связь<br> от  экспертов и куратора.
                            Получите ее<br> в групповых чатах и на Q&A-эфирах</p>
                        <div class="club40-tariffs-item-divider"></div>
                        <p class="club40-tariffs-item-subheader">В тариф входят все опции тарифа «Самостоятельный», плюс:
                        </p>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>Доступ к сообществу</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>Доступ ко всем онлайн-ивентам и интенсивам</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>2 Q&A-эфира в месяц с разбором ваших карьерных вопросов</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="careerum-flex-fsfs club40-tariffs-item-wrapper-price">
                                <div>
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div>
                                    <p>/</p>
                                </div>
                                <div>
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#thanks-yes" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="27 900 ₽"
                        data-price-3m-rub="23 900 ₽" data-fullprice-3m-rub="71 700 ₽" data-price-6m-rub="18 317 ₽"
                        data-fullprice-6m-rub="109 900 ₽" data-price-12m-rub="16 583 ₽" data-fullprice-12m-rub="199 000 ₽"
                        data-price-1m-eur="360 €" data-price-3m-eur="310 €" data-fullprice-3m-eur="930 €"
                        data-price-6m-eur="250 €" data-fullprice-6m-eur="1 500 €" data-price-12m-eur="230 €"
                        data-fullprice-12m-eur="2 760 €" data-offer-1m-rub="#1mr" data-offer-3m-rub="#3mr"
                        data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr" data-offer-1m-eur="#1me"
                        data-offer-3m-eur="#3me" data-offer-6m-eur="#6me" data-offer-12m-eur="#12me">
                        <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">Индивидуальный подход</p>
                        <p class="club40-tariffs-item-description">Когда надо прийти к цели быстро.<br> У вас будет
                            персональная поддержка наших карьерных экспертов</p>
                        <div class="club40-tariffs-item-divider"></div>
                        <p class="club40-tariffs-item-subheader">В тариф входят все преимущества тарифа «С поддержкой»,
                            плюс:</p>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>Персональная поддержка карьерного эксперта<br> и карьерного коуча в чате</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>2 видеоконсультации<br> в месяц с коучем или экспертом</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723136684555-7a9hK-Fram.svg"
                                    alt="">
                                <p>Персональный разбор вашего резюме и LinkedIn-профиля</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="careerum-flex-fsfs club40-tariffs-item-wrapper-price">
                                <div>
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div>
                                    <p>/</p>
                                </div>
                                <div>
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#thanks" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-bottom careerum-flex-sbfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278649360-d3raW-Vect.svg"
                            alt="">
                        <p class="club40-tariffs-bottom-header">Не знаете, с чего начать?</p>
                        <p class="club40-tariffs-bottom-subheader">Напишите нам — мы ответим на все вопросы<br> и поможем
                            выбрать лучшее решение под вашу задачу</p>
                        <a href="#tg" class="btn-color-main-dark-orange careerum-flex-cc">
                            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.9104 12.5754L9.51374 19.781C9.51374 19.781 9.83922 20.4552 10.1877 20.4552C10.5363 20.4552 15.7201 15.0624 15.7201 15.0624L21.4847 3.92822L7.00329 10.7153L6.9104 12.5754Z"
                                    fill="#C8DAEA" />
                                <path
                                    d="M10.3623 14.4233L9.86251 19.7346C9.86251 19.7346 9.65332 21.362 11.2804 19.7346C12.9074 18.1072 14.4648 16.8522 14.4648 16.8522"
                                    fill="#A9C6D8" />
                                <path
                                    d="M6.9574 12.8324L1.60225 11.0876C1.60225 11.0876 0.962247 10.828 1.16833 10.2392C1.21075 10.1177 1.29633 10.0144 1.55233 9.83687C2.73889 9.00981 23.5146 1.54247 23.5146 1.54247C23.5146 1.54247 24.1012 1.3448 24.4471 1.47628C24.5327 1.50277 24.6097 1.55152 24.6703 1.61755C24.7309 1.68357 24.7728 1.7645 24.7918 1.85205C24.8292 2.00668 24.8448 2.16578 24.8383 2.32473C24.8366 2.46224 24.82 2.58969 24.8074 2.78956C24.6808 4.83116 20.8942 20.0683 20.8942 20.0683C20.8942 20.0683 20.6677 20.9599 19.856 20.9904C19.6565 20.9969 19.4578 20.9631 19.2716 20.8911C19.0855 20.8191 18.9157 20.7103 18.7725 20.5713C17.1797 19.2012 11.6742 15.5012 10.4577 14.6875C10.4302 14.6688 10.4071 14.6445 10.3899 14.6161C10.3727 14.5877 10.3617 14.5559 10.3578 14.523C10.3408 14.4372 10.4341 14.3309 10.4341 14.3309C10.4341 14.3309 20.0205 5.80981 20.2756 4.91527C20.2954 4.84597 20.2208 4.81177 20.1206 4.84213C19.4838 5.07637 8.44622 12.0467 7.22803 12.816C7.14034 12.8425 7.04766 12.8481 6.9574 12.8324Z"
                                    fill="white" />
                            </svg>
                            <p>Написать нам в Telegram</p>
                        </a>
                    </div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278685081-7nUnx-ÑÐ¾.svg"
                            alt="">
                        <div class="careerum-flex-fsfs club40-tariffs-bottom-care">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278768285-Yeat8-phot.png"
                                    alt="">
                            </div>
                            <div>
                                <p class="club40-tariffs-bottom-care-name">Анна </p>
                                <p class="club40-tariffs-bottom-care-position">Команда заботы Careerum</p>
                                <p class="club40-tariffs-bottom-care-descr">Добрый день! Построить карьеру мечты в одиночку
                                    бывает непросто. Но мы рядом и приведем вас к заветной цели 💙</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-tariffs-tablet', {
        label: 'Club 4 tariffs (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-tariffs-tablet">
            <div class="careerum-container-tablet">
                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723485008387-JfPF7-Rect.png" alt="">
                <h2 class="careerum-club40-header-tablet">Оформите подписку Careerum </h2>
                <div class="club40-tariffs-nav careerum-flex-sbfs">
                    <div class="club40-tariffs-period">
                        <ul class="careerum-flex-fsfs">
                            <li data-period="12" class="active">12 месяцев</li>
                            <li data-period="6">6 месяцев</li>
                            <li data-period="3">3 месяца</li>
                            <li data-period="1">1 месяц</li>
                        </ul>
                    </div>
                </div>
                <div class="club40-tariffs-items">
                    <div class="careerum-flex-fsst">
                        <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="27 900 ₽"
                            data-price-3m-rub="23 900 ₽" data-fullprice-3m-rub="71 700 ₽" data-price-6m-rub="18 317 ₽"
                            data-fullprice-6m-rub="109 900 ₽" data-price-12m-rub="16 583 ₽"
                            data-fullprice-12m-rub="199 000 ₽" data-price-1m-eur="360 €" data-price-3m-eur="310 €"
                            data-fullprice-3m-eur="930 €" data-price-6m-eur="250 €" data-fullprice-6m-eur="1 500 €"
                            data-price-12m-eur="230 €" data-fullprice-12m-eur="2 760 €" data-offer-1m-rub="#1mr"
                            data-offer-3m-rub="#3mr" data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr"
                            data-offer-1m-eur="#1me" data-offer-3m-eur="#3me" data-offer-6m-eur="#6me"
                            data-offer-12m-eur="#12me">
                            <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                            <p class="club40-tariffs-item-subheader">Тариф</p>
                            <p class="club40-tariffs-item-header">Индивидуальный подход</p>
                            <p class="club40-tariffs-item-subheader">В тариф входят все преимущества тарифа «С поддержкой»,
                                плюс:</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>Персональная поддержка карьерного эксперта и карьерного коуча в чате</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>2 видеоконсультации<br> в месяц с коучем или экспертом</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>Персональный разбор вашего резюме и LinkedIn-профиля</p>
                                </li>
                            </ul>
                            <div class="club40-tariffs-item-bottom">
                                <div class="club40-tariffs-item-wrapper-price">
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                        <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                        <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                    </div>
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                        <p>полная стоимость к оплате</p>
                                    </div>
                                </div>
                                <a href="#payform-single" class="btn-color-main-dark-orange">Выбрать тариф</a>
                                <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                                <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                            </div>
                        </div>
                        <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="14 900 ₽"
                            data-price-3m-rub="11 633 ₽" data-fullprice-3m-rub="34 900 ₽" data-price-6m-rub="9 817 ₽"
                            data-fullprice-6m-rub="58 900 ₽" data-price-12m-rub="8 000 ₽" data-fullprice-12m-rub="96 000 ₽"
                            data-price-1m-eur="199 €" data-price-3m-eur="170 €" data-fullprice-3m-eur="510 €"
                            data-price-6m-eur="130 €" data-fullprice-6m-eur="780 €" data-price-12m-eur="100 €"
                            data-fullprice-12m-eur="1 200 €" data-offer-1m-rub="#1mr" data-offer-3m-rub="#3mr"
                            data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr" data-offer-1m-eur="#1me"
                            data-offer-3m-eur="#3me" data-offer-6m-eur="#6me" data-offer-12m-eur="#12me">
                            <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                            <p class="club40-tariffs-item-subheader">Тариф</p>
                            <p class="club40-tariffs-item-header">С поддержкой</p>
                            <p class="club40-tariffs-item-subheader">В тариф входят все опции тарифа «Самостоятельный»,
                                плюс: </p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>Доступ к сообществу</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>Доступ ко всем онлайн-ивентам и интенсивам</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                        alt="">
                                    <p>2 Q&A-эфира в месяц с разбором ваших карьерных вопросов</p>
                                </li>
                            </ul>
                            <div class="club40-tariffs-item-bottom">
                                <div class="club40-tariffs-item-wrapper-price">
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                        <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                        <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                    </div>
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                        <p>полная стоимость к оплате</p>
                                    </div>
                                </div>
                                <a href="#thanks-yes" class="btn-color-main-dark-orange">Выбрать тариф</a>
                                <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                                <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                            </div>
                        </div>
                        <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="12 900 ₽"
                            data-price-3m-rub="9 967 ₽" data-fullprice-3m-rub="29 900 ₽" data-price-6m-rub="6 900 ₽"
                            data-fullprice-6m-rub="47 400 ₽" data-price-12m-rub="5 900 ₽" data-fullprice-12m-rub="77 880 ₽"
                            data-price-1m-eur="149 €" data-price-3m-eur="120 €" data-fullprice-3m-eur="360 €"
                            data-price-6m-eur="90 €" data-fullprice-6m-eur="540 €" data-price-12m-eur="80 €"
                            data-fullprice-12m-eur="960 €" data-offer-1m-rub="#1mr1" data-offer-3m-rub="#3mr1"
                            data-offer-6m-rub="#6mr1" data-offer-12m-rub="#12mr1" data-offer-1m-eur="#1me1"
                            data-offer-3m-eur="#3me1" data-offer-6m-eur="#6me1" data-offer-12m-eur="#12me1">
                            <p class="club40-tariffs-item-subheader">Тариф</p>
                            <p class="club40-tariffs-item-header">Самостоятельный</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723552330061-yrrgy-Play.svg"
                                        alt="">
                                    <p>18 онлайн-программ,<br> закрывающих любой карьерный запрос</p>
                                </li>
                            </ul>
                            <div class="club40-tariffs-item-bottom">
                                <div class="club40-tariffs-item-wrapper-price">
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                        <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                        <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                    </div>
                                    <div class="careerum-flex-fsfs">
                                        <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                        <p>полная стоимость к оплате</p>
                                    </div>
                                </div>
                                <a href="#thanks-no" class="btn-color-main-dark-orange">Выбрать тариф</a>
                                <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                                <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-curr">
                    <div class="club40-tariffs__swicher-block">
                        <div class="club40-tariffs__swicher-top">
                            <p class="club40-tariffs__swicher-header">Посмотреть цены в
                                <span>RUB</span>
                            </p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" class="club40-tariffs__swicher-arrow">
                                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="black"></path>
                            </svg>
                        </div>
                        <div class="club40-tariffs__swicher-bottom">
                            <ul>
                                <li data-currency="rub">
                                    RUB
                                </li>
                                <li data-currency="eur" class="active">
                                    EUR
                                </li>
                            </ul>
                            <p>
                                Обратите внимание,<br> что оплата в российских рублях
                                возможна только<br> с карты
                                российского банка. Оплата в евро — с карты зарубежного банка
                            </p>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-bottom careerum-flex-sbfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723484897716-GBGcE-Vect.svg"
                            alt="">
                        <p class="club40-tariffs-bottom-header">Не знаете, с чего начать?</p>
                        <p class="club40-tariffs-bottom-subheader">Напишите нам — мы ответим на все вопросы и поможем
                            выбрать решение под вашу задачу</p>
                        <a href="#tg" class="btn-color-main-dark-orange careerum-flex-cc">
                            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.9104 12.5754L9.51374 19.781C9.51374 19.781 9.83922 20.4552 10.1877 20.4552C10.5363 20.4552 15.7201 15.0624 15.7201 15.0624L21.4847 3.92822L7.00329 10.7153L6.9104 12.5754Z"
                                    fill="#C8DAEA" />
                                <path
                                    d="M10.3623 14.4233L9.86251 19.7346C9.86251 19.7346 9.65332 21.362 11.2804 19.7346C12.9074 18.1072 14.4648 16.8522 14.4648 16.8522"
                                    fill="#A9C6D8" />
                                <path
                                    d="M6.9574 12.8324L1.60225 11.0876C1.60225 11.0876 0.962247 10.828 1.16833 10.2392C1.21075 10.1177 1.29633 10.0144 1.55233 9.83687C2.73889 9.00981 23.5146 1.54247 23.5146 1.54247C23.5146 1.54247 24.1012 1.3448 24.4471 1.47628C24.5327 1.50277 24.6097 1.55152 24.6703 1.61755C24.7309 1.68357 24.7728 1.7645 24.7918 1.85205C24.8292 2.00668 24.8448 2.16578 24.8383 2.32473C24.8366 2.46224 24.82 2.58969 24.8074 2.78956C24.6808 4.83116 20.8942 20.0683 20.8942 20.0683C20.8942 20.0683 20.6677 20.9599 19.856 20.9904C19.6565 20.9969 19.4578 20.9631 19.2716 20.8911C19.0855 20.8191 18.9157 20.7103 18.7725 20.5713C17.1797 19.2012 11.6742 15.5012 10.4577 14.6875C10.4302 14.6688 10.4071 14.6445 10.3899 14.6161C10.3727 14.5877 10.3617 14.5559 10.3578 14.523C10.3408 14.4372 10.4341 14.3309 10.4341 14.3309C10.4341 14.3309 20.0205 5.80981 20.2756 4.91527C20.2954 4.84597 20.2208 4.81177 20.1206 4.84213C19.4838 5.07637 8.44622 12.0467 7.22803 12.816C7.14034 12.8425 7.04766 12.8481 6.9574 12.8324Z"
                                    fill="white" />
                            </svg>
                            <p>Написать нам в Telegram</p>
                        </a>
                    </div>
                    <div>
                        <div class="club40-tariffs-bottom-care">
                            <div class="careerum-flex-fsc">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278768285-Yeat8-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-tariffs-bottom-care-name">Анна </p>
                                    <p class="club40-tariffs-bottom-care-position">Команда заботы Careerum</p>
                                </div>
                            </div>
                            <p class="club40-tariffs-bottom-care-descr">Добрый день! Построить карьеру мечты в одиночку
                                бывает непросто. Но мы рядом и приведем вас к заветной цели 💙</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-tariffs-mobile', {
        label: 'Club 4 tariffs (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-tariffs-mobile">
            <div class="careerum-container-mobile">
                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723551361905-BYCit-Rect.png" alt="">
                <h2 class="careerum-club40-header-mobile">Оформите подписку<br> Careerum </h2>
                <div class="club40-tariffs-nav">
                    <div class="club40-tariffs-period">
                        <ul class="careerum-flex-fsfs">
                            <li data-period="12" class="active">Год</li>
                            <li data-period="6">Полгода</li>
                            <li data-period="3">3 месяца</li>
                            <li data-period="1">Месяц</li>
                        </ul>
                    </div>
                </div>
                <div class="club40-tariffs-curr">
                    <div class="club40-tariffs__swicher-block">
                        <div class="club40-tariffs__swicher-top">
                            <p class="club40-tariffs__swicher-header">Посмотреть цены в
                                <span>RUB</span>
                            </p><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" class="club40-tariffs__swicher-arrow">
                                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="black"></path>
                            </svg>
                        </div>
                        <div class="club40-tariffs__swicher-bottom">
                            <ul>
                                <li data-currency="rub">
                                    RUB
                                </li>
                                <li data-currency="eur" class="active">
                                    EUR
                                </li>
                            </ul>
                            <p>
                                Обратите внимание,<br> что оплата в российских рублях
                                возможна только<br> с карты
                                российского банка. Оплата в евро — с карты зарубежного банка
                            </p>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-items">
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="12 900 ₽"
                        data-price-3m-rub="9 967 ₽" data-fullprice-3m-rub="29 900 ₽" data-price-6m-rub="6 900 ₽"
                        data-fullprice-6m-rub="47 400 ₽" data-price-12m-rub="5 900 ₽" data-fullprice-12m-rub="77 880 ₽"
                        data-price-1m-eur="149 €" data-price-3m-eur="120 €" data-fullprice-3m-eur="360 €"
                        data-price-6m-eur="90 €" data-fullprice-6m-eur="540 €" data-price-12m-eur="80 €"
                        data-fullprice-12m-eur="960 €" data-offer-1m-rub="#1mr1" data-offer-3m-rub="#3mr1"
                        data-offer-6m-rub="#6mr1" data-offer-12m-rub="#12mr1" data-offer-1m-eur="#1me1"
                        data-offer-3m-eur="#3me1" data-offer-6m-eur="#6me1" data-offer-12m-eur="#12me1">
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">Самостоятельный</p>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723552330061-yrrgy-Play.svg"
                                    alt="">
                                <p>18 онлайн-программ,<br> закрывающих любой карьерный запрос</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="club40-tariffs-item-wrapper-price">
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#thanks" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="14 900 ₽"
                        data-price-3m-rub="11 633 ₽" data-fullprice-3m-rub="34 900 ₽" data-price-6m-rub="9 817 ₽"
                        data-fullprice-6m-rub="58 900 ₽" data-price-12m-rub="8 000 ₽" data-fullprice-12m-rub="96 000 ₽"
                        data-price-1m-eur="199 €" data-price-3m-eur="170 €" data-fullprice-3m-eur="510 €"
                        data-price-6m-eur="130 €" data-fullprice-6m-eur="780 €" data-price-12m-eur="100 €"
                        data-fullprice-12m-eur="1 200 €" data-offer-1m-rub="#1mr" data-offer-3m-rub="#3mr"
                        data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr" data-offer-1m-eur="#1me"
                        data-offer-3m-eur="#3me" data-offer-6m-eur="#6me" data-offer-12m-eur="#12me">
                        <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">С поддержкой</p>
                        <p class="club40-tariffs-item-subheader">Все преимущества тарифа «Самостоятельный», плюс:</p>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>Доступ к сообществу</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>Доступ ко всем онлайн-ивентам и интенсивам</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>2 Q&A-эфира в месяц с разбором ваших карьерных вопросов</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="club40-tariffs-item-wrapper-price">
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#thanks-yes" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                    <div class="club40-tariffs-item careerum-flex-fsfs" data-price-1m-rub="27 900 ₽"
                        data-price-3m-rub="23 900 ₽" data-fullprice-3m-rub="71 700 ₽" data-price-6m-rub="18 317 ₽"
                        data-fullprice-6m-rub="109 900 ₽" data-price-12m-rub="16 583 ₽" data-fullprice-12m-rub="199 000 ₽"
                        data-price-1m-eur="360 €" data-price-3m-eur="310 €" data-fullprice-3m-eur="930 €"
                        data-price-6m-eur="250 €" data-fullprice-6m-eur="1 500 €" data-price-12m-eur="230 €"
                        data-fullprice-12m-eur="2 760 €" data-offer-1m-rub="#1mr" data-offer-3m-rub="#3mr"
                        data-offer-6m-rub="#6mr" data-offer-12m-rub="#12mr" data-offer-1m-eur="#1me"
                        data-offer-3m-eur="#3me" data-offer-6m-eur="#6me" data-offer-12m-eur="#12me">
                        <p class="club40-tariffs-item-label">Осталось: 25 мест</p>
                        <p class="club40-tariffs-item-subheader">Тариф</p>
                        <p class="club40-tariffs-item-header">Индивидуальный подход</p>
                        <p class="club40-tariffs-item-subheader">Все преимущества тарифа «С поддержкой», плюс:</p>
                        <ul>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>Персональная поддержка карьерного эксперта и карьерного коуча в чате</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>2 видеоконсультации<br> в месяц с коучем или экспертом</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723483406167-XJY0e-Fram.svg"
                                    alt="">
                                <p>Персональный разбор вашего резюме и LinkedIn-профиля</p>
                            </li>
                        </ul>
                        <div class="club40-tariffs-item-bottom">
                            <div class="club40-tariffs-item-wrapper-price">
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-pm">8 000 ₽</p>
                                    <p class="club40-tariffs-item-price-pmn1m">цена месяца в пакете</p>
                                    <p class="club40-tariffs-item-price-pm1m">в месяц</p>
                                </div>
                                <div class="careerum-flex-fsfs">
                                    <p class="club40-tariffs-item-price-f">96 000 ₽</p>
                                    <p>полная стоимость к оплате</p>
                                </div>
                            </div>
                            <a href="#payform-single" class="btn-color-main-dark-orange">Выбрать тариф</a>
                            <p class="club40-tariffs-item-price-rassr-eur">Можно платить долями</p>
                            <p class="club40-tariffs-item-price-rassr-rub">Можно в рассрочку</p>
                        </div>
                    </div>
                </div>
                <div class="club40-tariffs-bottom">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723484897716-GBGcE-Vect.svg"
                            alt="">
                        <p class="club40-tariffs-bottom-header">Не знаете, с чего начать?</p>
                        <p class="club40-tariffs-bottom-subheader">Напишите нам — мы ответим на все вопросы и поможем
                            выбрать решение под вашу задачу</p>
                    </div>
                    <div>
                        <div class="club40-tariffs-bottom-care">
                            <div class="careerum-flex-fsc">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723278768285-Yeat8-phot.png"
                                        alt="">
                                </div>
                                <div>
                                    <p class="club40-tariffs-bottom-care-name">Анна </p>
                                    <p class="club40-tariffs-bottom-care-position">Команда заботы Careerum</p>
                                </div>
                            </div>
                            <p class="club40-tariffs-bottom-care-descr">Добрый день! Построить карьеру мечты в одиночку
                                бывает непросто. Но мы рядом и приведем вас к заветной цели 💙</p>
                        </div>
                        <a href="#tg" class="btn-color-main-dark-orange careerum-flex-cc">
                            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.9104 12.5754L9.51374 19.781C9.51374 19.781 9.83922 20.4552 10.1877 20.4552C10.5363 20.4552 15.7201 15.0624 15.7201 15.0624L21.4847 3.92822L7.00329 10.7153L6.9104 12.5754Z"
                                    fill="#C8DAEA" />
                                <path
                                    d="M10.3623 14.4233L9.86251 19.7346C9.86251 19.7346 9.65332 21.362 11.2804 19.7346C12.9074 18.1072 14.4648 16.8522 14.4648 16.8522"
                                    fill="#A9C6D8" />
                                <path
                                    d="M6.9574 12.8324L1.60225 11.0876C1.60225 11.0876 0.962247 10.828 1.16833 10.2392C1.21075 10.1177 1.29633 10.0144 1.55233 9.83687C2.73889 9.00981 23.5146 1.54247 23.5146 1.54247C23.5146 1.54247 24.1012 1.3448 24.4471 1.47628C24.5327 1.50277 24.6097 1.55152 24.6703 1.61755C24.7309 1.68357 24.7728 1.7645 24.7918 1.85205C24.8292 2.00668 24.8448 2.16578 24.8383 2.32473C24.8366 2.46224 24.82 2.58969 24.8074 2.78956C24.6808 4.83116 20.8942 20.0683 20.8942 20.0683C20.8942 20.0683 20.6677 20.9599 19.856 20.9904C19.6565 20.9969 19.4578 20.9631 19.2716 20.8911C19.0855 20.8191 18.9157 20.7103 18.7725 20.5713C17.1797 19.2012 11.6742 15.5012 10.4577 14.6875C10.4302 14.6688 10.4071 14.6445 10.3899 14.6161C10.3727 14.5877 10.3617 14.5559 10.3578 14.523C10.3408 14.4372 10.4341 14.3309 10.4341 14.3309C10.4341 14.3309 20.0205 5.80981 20.2756 4.91527C20.2954 4.84597 20.2208 4.81177 20.1206 4.84213C19.4838 5.07637 8.44622 12.0467 7.22803 12.816C7.14034 12.8425 7.04766 12.8481 6.9574 12.8324Z"
                                    fill="white" />
                            </svg>
                            <p>Написать нам в Telegram</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-support', {
        label: 'Club 4 support',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-support">
            <div class="careerum-container-desc">
                <h2 class="careerum-club40-header">Еще немного про подписку Careerum</h2>
                <div class="club40-support-experts">
                    <div class="club40-support-experts-top">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Экспертная поддержка</p>
                        </div>
                        <p class="club40-support-header">Помощь карьерных коучей и экспертов:<br>
                            чтобы карьерные цели достигались легко и быстро</p>
                    </div>
                    <div class="club40-support-experts-slider">
                        <div class="club40-support-experts-slider-item-about">
                            <div>
                                <div>
                                    <p class="club40-support-experts-slider-item-header">9,3/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка коучей от
                                        клиентов Careerum</p>
                                    <p class="club40-support-experts-slider-item-header">9,5/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка карьерных
                                        экспертов</p>
                                </div>
                            </div>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280806527-AkUu1-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Добринская</p>
                            <p class="club40-support-experts-slider-item-position">PCC ICF </p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге и финансах </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1700+ клиентам обрести уверенность и подготовиться к собеседованиям</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Sony, Ernst&Young, Deloitte</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>ОАЭ</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409766-cqwPu-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Софи Бессон</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ в HR и карьерном консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 700+ клиентам составить резюме и найти работу в Европе, США и Канаде</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Disney, Intel, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Израиль</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409265-9vjpf-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Надежда Община</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>9+ лет в маркетинге и консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 550+ клиентам из США, Европы и СНГ найти работу и сменить карьеру</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Airbus, Hubspot, Google, Farfetch</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Португалия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410646-Tg23o-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Яна Хмельницкая</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>15+ лет в HR, карьерном коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 800+ клиентам подготовиться к интервью на английском языке и релоцироваться
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Ford, Leroy Merlin, Unilever, Mars</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410772-6unNS-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Оксана Сыпченко</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет в карьерном коучинге и гос.секторе</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 100+ клиентам найти карьерный вектор, стать увереннее в себе и получить
                                        повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками ERG, DevExpress, Роснефть</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Конго</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410266-0RLoj-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Эльзенбах</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет опыта в коучинге, НКО и EdTech</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1300+ клиентам из РФ, Европы и СНГ получить оффер через укрепление самооценки
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Lamoda, Яндекс, Mail, InDriver</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410383-HztZL-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Виктория Албаутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICU</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и маркетинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 150+ клиентам построить выигрышные стратегии и бренды</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        компаниями Beiesdorf, Philips, Johnson&Johnson, Novartis</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410508-UN5w7-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Светлана Шуйская</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 300+ клиентам вырасти в доходе, составить стратегию, начать свое дело</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Metro Cash&Carry, Yandex, Avito</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409625-XnYoE-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Мария Пудалова</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>7+ лет в PR и стратегии выстраивания бренда</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 400+ клиентам улучшить CV и найти работу в Европе
                                        и США</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Deloitte, BBDO, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Германия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409884-sm2PF-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Астра Висаева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге, финансах и инвестициях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2500+ клиентам определить карьерные вектора и найти работу в России, ЕС и США
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками OZON, EPAM, McKinsey, Nike</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410030-PrNQ2-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Марина Веденичева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч PCC ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в HR и карьерном коучинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2000+ клиентам усилить лидерские качества и получить повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Mars, Shell, Ernst & Young</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Великобритания</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410151-lWIq0-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Ирина Шутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч AFC</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ лет в коучинге и коммуникациях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1000+ клиентам получить офферы с релокацией и повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками PepsiCo, Amazon, VK, Pfizer</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="club40-support-slider-nav careerum-flex-fec">
                        <div class="club40-support-experts-slider-nav-prev">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7304_21282)">
                                    <g filter="url(#filter0_b_7304_21282)">
                                        <rect y="40" width="40" height="40" transform="rotate(-90 0 40)" fill="#FF9F00" />
                                    </g>
                                    <path d="M13.1905 19.9993L26.8105 19.9993" stroke="white" stroke-width="2.64201"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M19.4629 26.2453L13.1896 19.9986L19.4629 13.752" stroke="white"
                                        stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <filter id="filter0_b_7304_21282" x="-18.1166" y="-18.1166" width="76.2332"
                                        height="76.2332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                        <feComposite in2="SourceAlpha" operator="in"
                                            result="effect1_backgroundBlur_7304_21282" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21282"
                                            result="shape" />
                                    </filter>
                                    <clipPath id="clip0_7304_21282">
                                        <rect y="40" width="40" height="40" rx="20" transform="rotate(-90 0 40)"
                                            fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="club40-support-experts-slider-nav-next">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7304_21286)">
                                    <g filter="url(#filter0_b_7304_21286)">
                                        <rect x="40" width="40" height="40" transform="rotate(90 40 0)" fill="#FF9F00" />
                                    </g>
                                    <path d="M26.8095 20.0007L13.1895 20.0007" stroke="white" stroke-width="2.64201"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M20.5371 13.7547L26.8104 20.0014L20.5371 26.248" stroke="white"
                                        stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <filter id="filter0_b_7304_21286" x="-18.1166" y="-18.1166" width="76.2332"
                                        height="76.2332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                        <feComposite in2="SourceAlpha" operator="in"
                                            result="effect1_backgroundBlur_7304_21286" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21286"
                                            result="shape" />
                                    </filter>
                                    <clipPath id="clip0_7304_21286">
                                        <rect x="40" width="40" height="40" rx="20" transform="rotate(90 40 0)"
                                            fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="club40-support-experts-available careerum-flex-fsc">
                        <p>Доступно на тарифах:</p>
                        <div class="careerum-flex-cc not-avail">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283080104-KyTpV-XCir.svg"
                                alt="">
                            <p>Самостоятельный</p>
                        </div>
                        <div class="careerum-flex-cc not-avail">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283080104-KyTpV-XCir.svg"
                                alt="">
                            <p>С поддержкой</p>
                        </div>
                        <div class="careerum-flex-cc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                alt="">
                            <p>Индивидуальный подход</p>
                        </div>
                    </div>
                </div>
                <div class="club40-support-info careerum-flex-sbst">
                    <div class="club40-support-mers">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Онлайн мероприятия</p>
                        </div>
                        <p class="club40-support-header">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-support-content">На интенсивах глубоко погружаемся в ключевые карьерные темы и
                            помогаем каждому участнику быстро и эффективно достичь своей цели с поддержкой единомышленников
                            и экспертов.</p>
                        <p class="club40-support-content">На Q&A-эфирах вместе с карьерными экспертами и другими участниками
                            сообщества решаем актуальные карьерные вопросы.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283725047-fSheB-Fram.svg"
                            alt="">
                        <div class="club40-support-experts-available careerum-flex-fsc">
                            <p>Доступно на тарифах:</p>
                            <div class="careerum-flex-cc not-avail">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283080104-KyTpV-XCir.svg"
                                    alt="">
                                <p>Самостоятельный</p>
                            </div>
                            <div class="careerum-flex-cc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                    alt="">
                                <p>С поддержкой</p>
                            </div>
                            <div class="careerum-flex-cc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                    alt="">
                                <p>Индивидуальный подход</p>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-netw">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Нетворкинг</p>
                        </div>
                        <p class="club40-support-header">Сильное сообщество профессионалов со всего мира</p>
                        <p class="club40-support-content">Доступ в сообщество из 1000+ профессионалов из 30+ стран, где вы
                            сможете научиться строить нетворк и завести полезные знакомства, найти поддержку среди
                            участников с похожими проблемами и целями и получить советы от тех, кто уже успешно прошел
                            аналогичный путь.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723284054075-4V27u-Grou.svg"
                            alt="">
                        <div class="club40-support-experts-available careerum-flex-fsc">
                            <p>Доступно на тарифах:</p>
                            <div class="careerum-flex-cc not-avail">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283080104-KyTpV-XCir.svg"
                                    alt="">
                                <p>Самостоятельный</p>
                            </div>
                            <div class="careerum-flex-cc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                    alt="">
                                <p>С поддержкой</p>
                            </div>
                            <div class="careerum-flex-cc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                    alt="">
                                <p>Индивидуальный подход</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-support-mats">
                    <div class="club40-support-mats-top careerum-flex-sbfs">
                        <div>
                            <div class="club40-support-label careerum-flex-fsfs">
                                <p>Образовательные материалы</p>
                            </div>
                            <p class="club40-support-header">18 программ под любую карьерную задачу, и даже самую редкую</p>
                            <ul>
                                <li>Как построить карьеру за рубежом</li>
                                <li>Как адаптироваться на новом рабочем месте</li>
                                <li>Как регулярно расти в должности и доходе</li>
                                <li>Как укрепить профессиональную самооценку</li>
                                <li>Как построить life-work balance и не выгорать</li>
                                <li>И другие темы, чтобы карьера была по любви</li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723284844056-jWdN3-Note.svg"
                                            alt="">
                                        <p>В Сareerum больше 80 курсов под любой запрос</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723284843668-UVBRz-Book.svg"
                                            alt="">
                                        <p>Методисты Careerum разделили их по темам и упаковали в понятные образовательные
                                            траектории</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723284844268-BBlL8-Trop.svg"
                                            alt="">
                                        <p>Вам осталось выбрать подходящую и получить знания. А если возникнут вопросы, мы
                                            обязательно поможем!</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-mats-divider"></div>
                    <div class="club40-support-mats-slider-wrapper">
                        <p class="club40-support-mats-slider-header">Учитесь по программам ведущих экспертов</p>
                        <div class="club40-support-mats-slider-items">
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285436842-nmhub-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Ольга Лермонтова</p>
                                <p class="club40-support-mats-slider-item-position">фаундер Careerum</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Занимала руководящие должности в Google и Microsoft</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Управляла бизнесом на 20+ рынках Африки и Европы</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>12 000+ клиентов из РФ, СНГ, Европы, Америки </p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Италия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437123-F7q7s-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Елена Резанова</p>
                                <p class="club40-support-mats-slider-item-position">карьерный стратег</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>9+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогает посмотреть на карьеру целостно и соотнести ее с целями бизнеса.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книг-бестселлеров «Никогда-нибудь» и «Это норм!»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Монако </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437246-oRSRS-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Sophie Vurpillot</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Испания</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437367-I19r8-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Анна Бабич</p>
                                <p class="club40-support-mats-slider-item-position">психолог</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Терапевт в сфере АСТ и эмоционально-фокусированной терапии</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книги-бестселлера «Внутренняя опора»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Спикер конференций: Psychologies, TeamLead, CodeFest</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Россия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437505-QfSia-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Montse Lorente</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Перу</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437627-rUPvo-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Санжар Ибрагимов</p>
                                <p class="club40-support-mats-slider-item-position">ментор</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Опыт в консалтинге в McKinsey & Company, PwC, World Bank Group</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>MBA в швейцарской бизнес-школе IMD</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в IMD International и Aalto Business School</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Финляндия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437755-4zqkR-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Наталья Токарь</p>
                                <p class="club40-support-mats-slider-item-position">эксперт по коммуникации</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>CEO проекта по обучению команд маркетинга, продаж, проджект-менеджеров и
                                            руководителей</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в бизнес-акселераторе Чикаго</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>США</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-slider-nav careerum-flex-fec">
                        <div class="club40-support-mats-slider-nav-prev">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7304_21282)">
                                    <g filter="url(#filter0_b_7304_21282)">
                                        <rect y="40" width="40" height="40" transform="rotate(-90 0 40)" fill="#FF9F00" />
                                    </g>
                                    <path d="M13.1905 19.9993L26.8105 19.9993" stroke="white" stroke-width="2.64201"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M19.4629 26.2453L13.1896 19.9986L19.4629 13.752" stroke="white"
                                        stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <filter id="filter0_b_7304_21282" x="-18.1166" y="-18.1166" width="76.2332"
                                        height="76.2332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                        <feComposite in2="SourceAlpha" operator="in"
                                            result="effect1_backgroundBlur_7304_21282" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21282"
                                            result="shape" />
                                    </filter>
                                    <clipPath id="clip0_7304_21282">
                                        <rect y="40" width="40" height="40" rx="20" transform="rotate(-90 0 40)"
                                            fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="club40-support-mats-slider-nav-next">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7304_21286)">
                                    <g filter="url(#filter0_b_7304_21286)">
                                        <rect x="40" width="40" height="40" transform="rotate(90 40 0)" fill="#FF9F00" />
                                    </g>
                                    <path d="M26.8095 20.0007L13.1895 20.0007" stroke="white" stroke-width="2.64201"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M20.5371 13.7547L26.8104 20.0014L20.5371 26.248" stroke="white"
                                        stroke-width="2.64201" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <filter id="filter0_b_7304_21286" x="-18.1166" y="-18.1166" width="76.2332"
                                        height="76.2332" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.05831" />
                                        <feComposite in2="SourceAlpha" operator="in"
                                            result="effect1_backgroundBlur_7304_21286" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7304_21286"
                                            result="shape" />
                                    </filter>
                                    <clipPath id="clip0_7304_21286">
                                        <rect x="40" width="40" height="40" rx="20" transform="rotate(90 40 0)"
                                            fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="club40-support-experts-available careerum-flex-fsc">
                        <p>Доступно на тарифах:</p>
                        <div class="careerum-flex-cc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                alt="">
                            <p>Самостоятельный</p>
                        </div>
                        <div class="careerum-flex-cc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                alt="">
                            <p>С поддержкой</p>
                        </div>
                        <div class="careerum-flex-cc">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723283079831-F19gL-Chec.svg"
                                alt="">
                            <p>Индивидуальный подход</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-support-tablet', {
        label: 'Club 4 support (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-support-tablet">
            <div class="careerum-container-tablet">
                <h2 class="careerum-club40-header-tablet">Еще немного про подписку</h2>
                <div class="club40-support-experts">
                    <div class="club40-support-experts-top careerum-flex-fsfs">
                        <div>
                            <div class="club40-support-label careerum-flex-fsfs">
                                <p>Экспертная поддержка</p>
                            </div>
                            <p class="club40-support-header">Помощь коучей и экспертов: чтобы карьерные цели достигались
                                легко и быстро</p>
                        </div>
                        <div>
                            <div>
                                <div class="careerum-flex-fsc">
                                    <p class="club40-support-experts-slider-item-header">9,3/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка коучей от
                                        клиентов Careerum</p>
                                </div>
                                <div class="careerum-flex-fsc">
                                    <p class="club40-support-experts-slider-item-header">9,5/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка карьерных
                                        экспертов</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-experts-slider">
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280806527-AkUu1-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Добринская</p>
                            <p class="club40-support-experts-slider-item-position">PCC ICF </p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге и финансах </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1700+ клиентам обрести уверенность и подготовиться к собеседованиям</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Sony, Ernst&Young, Deloitte</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>ОАЭ</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409766-cqwPu-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Софи Бессон</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ в HR и карьерном консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 700+ клиентам составить резюме и найти работу в Европе, США и Канаде</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Disney, Intel, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Израиль</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409265-9vjpf-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Надежда Община</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>9+ лет в маркетинге и консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 550+ клиентам из США, Европы и СНГ найти работу и сменить карьеру</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Airbus, Hubspot, Google, Farfetch</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Португалия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410646-Tg23o-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Яна Хмельницкая</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>15+ лет в HR, карьерном коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 800+ клиентам подготовиться к интервью на английском языке и релоцироваться
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Ford, Leroy Merlin, Unilever, Mars</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410772-6unNS-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Оксана Сыпченко</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет в карьерном коучинге и гос.секторе</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 100+ клиентам найти карьерный вектор, стать увереннее в себе и получить
                                        повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками ERG, DevExpress, Роснефть</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Конго</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410266-0RLoj-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Эльзенбах</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет опыта в коучинге, НКО и EdTech</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1300+ клиентам из РФ, Европы и СНГ получить оффер через укрепление самооценки
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Lamoda, Яндекс, Mail, InDriver</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410383-HztZL-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Виктория Албаутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICU</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и маркетинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 150+ клиентам построить выигрышные стратегии и бренды</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        компаниями Beiesdorf, Philips, Johnson&Johnson, Novartis</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410508-UN5w7-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Светлана Шуйская</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 300+ клиентам вырасти в доходе, составить стратегию, начать свое дело</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Metro Cash&Carry, Yandex, Avito</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409625-XnYoE-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Мария Пудалова</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>7+ лет в PR и стратегии выстраивания бренда</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 400+ клиентам улучшить CV и найти работу в Европе
                                        и США</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Deloitte, BBDO, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Германия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409884-sm2PF-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Астра Висаева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге, финансах и инвестициях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2500+ клиентам определить карьерные вектора и найти работу в России, ЕС и США
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками OZON, EPAM, McKinsey, Nike</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410030-PrNQ2-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Марина Веденичева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч PCC ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в HR и карьерном коучинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2000+ клиентам усилить лидерские качества и получить повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Mars, Shell, Ernst & Young</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Великобритания</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410151-lWIq0-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Ирина Шутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч AFC</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ лет в коучинге и коммуникациях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1000+ клиентам получить офферы с релокацией и повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками PepsiCo, Amazon, VK, Pfizer</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="club40-support-info careerum-flex-sbst">
                    <div class="club40-support-mers">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Онлайн мероприятия</p>
                        </div>
                        <p class="club40-support-header">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-support-content">На интенсивах глубоко погружаемся в ключевые карьерные темы и помогаем каждому участнику достичь своей цели с поддержкой единомышленников и экспертов.</p>
                        <p class="club40-support-content">На Q&A-эфирах вместе с карьерными экспертами и другими участниками
                            сообщества решаем актуальные карьерные вопросы.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723487517601-J3a3P-Fram.svg"
                            alt="">
                    </div>
                    <div class="club40-support-netw">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Нетворкинг</p>
                        </div>
                        <p class="club40-support-header">Сильное сообщество профессионалов со всего мира</p>
                        <p class="club40-support-content">Доступ в сообщество из 1000+ профессионалов из 30+ стран, где вы сможете завести полезные знакомства, найти поддержку среди участников с похожими проблемами и целями и получить советы от тех, кто уже успешно прошел аналогичный путь.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723487518161-WYb3k-Grou.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-support-mats">
                    <div class="club40-support-mats-top careerum-flex-sbfs">
                        <div>
                            <div class="club40-support-label careerum-flex-fsfs">
                                <p>Образовательные материалы</p>
                            </div>
                            <p class="club40-support-header">18 программ под любую карьерную задачу</p>
                            <ul>
                                <li>Построить карьеру за рубежом</li>
                                <li>Адаптироваться на новом рабочем месте</li>
                                <li>Регулярно расти в должности и доходе</li>
                                <li>Укрепить профессиональную самооценку</li>
                                <li>И другие темы, даже самые редкие</li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217563-Vnu5D-Note.svg"
                                            alt="">
                                        <p>В Сareerum больше 80 курсов<br> под любой запрос</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217283-YRFlS-Book.svg"
                                            alt="">
                                        <p>Методисты Careerum разделили<br> их по темам на программы и упаковали в понятные образовательные траектории</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217709-BpfbH-Trop.svg"
                                            alt="">
                                        <p>Вам осталось выбрать подходящую, а если возникнут вопросы — мы рядом</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-mats-divider"></div>
                    <div class="club40-support-mats-slider-wrapper">
                        <p class="club40-support-mats-slider-header">Учитесь по программам ведущих экспертов</p>
                        <div class="club40-support-mats-slider-items">
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285436842-nmhub-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Ольга Лермонтова</p>
                                <p class="club40-support-mats-slider-item-position">фаундер Careerum</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Занимала руководящие должности в Google и Microsoft</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Управляла бизнесом на 20+ рынках Африки и Европы</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>12 000+ клиентов из РФ, СНГ, Европы, Америки </p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Италия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437123-F7q7s-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Елена Резанова</p>
                                <p class="club40-support-mats-slider-item-position">карьерный стратег</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>9+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогает посмотреть на карьеру целостно и соотнести ее с целями бизнеса.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книг-бестселлеров «Никогда-нибудь» и «Это норм!»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Монако </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437246-oRSRS-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Sophie Vurpillot</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Испания</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437367-I19r8-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Анна Бабич</p>
                                <p class="club40-support-mats-slider-item-position">психолог</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Терапевт в сфере АСТ и эмоционально-фокусированной терапии</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книги-бестселлера «Внутренняя опора»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Спикер конференций: Psychologies, TeamLead, CodeFest</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Россия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437505-QfSia-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Montse Lorente</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Перу</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437627-rUPvo-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Санжар Ибрагимов</p>
                                <p class="club40-support-mats-slider-item-position">ментор</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Опыт в консалтинге в McKinsey & Company, PwC, World Bank Group</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>MBA в швейцарской бизнес-школе IMD</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в IMD International и Aalto Business School</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Финляндия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437755-4zqkR-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Наталья Токарь</p>
                                <p class="club40-support-mats-slider-item-position">эксперт по коммуникации</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>CEO проекта по обучению команд маркетинга, продаж, проджект-менеджеров и
                                            руководителей</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в бизнес-акселераторе Чикаго</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>США</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-support-mobile', {
        label: 'Club 4 support (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-support-mobile">
            <div class="careerum-container-mobile">
                <h2 class="careerum-club40-header-mobile">Еще немного про подписку</h2>
                <div class="club40-support-experts">
                    <div class="club40-support-experts-top">
                        <div>
                            <div class="club40-support-label careerum-flex-fsfs">
                                <p>Экспертная поддержка</p>
                            </div>
                            <p class="club40-support-header">Помощь коучей и экспертов: чтобы карьерные цели достигались
                                легко и быстро</p>
                        </div>
                        <div>
                            <div>
                                <div class="careerum-flex-fsc">
                                    <p class="club40-support-experts-slider-item-header">9,3/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка коучей от
                                        клиентов Careerum</p>
                                </div>
                                <div class="careerum-flex-fsc">
                                    <p class="club40-support-experts-slider-item-header">9,5/10</p>
                                    <p class="club40-support-experts-slider-item-subheader">средняя оценка карьерных
                                        экспертов</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-experts-slider">
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280806527-AkUu1-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Добринская</p>
                            <p class="club40-support-experts-slider-item-position">PCC ICF </p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге и финансах </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1700+ клиентам обрести уверенность и подготовиться к собеседованиям</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Sony, Ernst&Young, Deloitte</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>ОАЭ</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409766-cqwPu-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Софи Бессон</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ в HR и карьерном консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 700+ клиентам составить резюме и найти работу в Европе, США и Канаде</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Disney, Intel, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Израиль</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409265-9vjpf-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Надежда Община</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>9+ лет в маркетинге и консультировании</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 550+ клиентам из США, Европы и СНГ найти работу и сменить карьеру</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Airbus, Hubspot, Google, Farfetch</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Португалия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410646-Tg23o-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Яна Хмельницкая</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>15+ лет в HR, карьерном коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 800+ клиентам подготовиться к интервью на английском языке и релоцироваться
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Ford, Leroy Merlin, Unilever, Mars</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410772-6unNS-ÑÐ¼.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Оксана Сыпченко</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет в карьерном коучинге и гос.секторе</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 100+ клиентам найти карьерный вектор, стать увереннее в себе и получить
                                        повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками ERG, DevExpress, Роснефть</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Конго</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410266-0RLoj-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Елена Эльзенбах</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>11+ лет опыта в коучинге, НКО и EdTech</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1300+ клиентам из РФ, Европы и СНГ получить оффер через укрепление самооценки
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Lamoda, Яндекс, Mail, InDriver</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410383-HztZL-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Виктория Албаутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICU</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и маркетинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 150+ клиентам построить выигрышные стратегии и бренды</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        компаниями Beiesdorf, Philips, Johnson&Johnson, Novartis</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Турция</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410508-UN5w7-ÐµÐ».png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Светлана Шуйская</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в коучинге и закупках</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 300+ клиентам вырасти в доходе, составить стратегию, начать свое дело</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Metro Cash&Carry, Yandex, Avito</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409625-XnYoE-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Мария Пудалова</p>
                            <p class="club40-support-experts-slider-item-position">Карьерный эксперт</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>7+ лет в PR и стратегии выстраивания бренда</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 400+ клиентам улучшить CV и найти работу в Европе
                                        и США</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Deloitte, BBDO, Microsoft</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Германия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282409884-sm2PF-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Астра Висаева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>10+ лет в коучинге, финансах и инвестициях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2500+ клиентам определить карьерные вектора и найти работу в России, ЕС и США
                                    </p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками OZON, EPAM, McKinsey, Nike</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410030-PrNQ2-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Марина Веденичева</p>
                            <p class="club40-support-experts-slider-item-position">Коуч PCC ICF</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>18+ лет в HR и карьерном коучинге</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 2000+ клиентам усилить лидерские качества и получить повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с
                                        сотрудниками Mars, Shell, Ernst & Young</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Великобритания</p>
                                </li>
                            </ul>
                        </div>
                        <div class="club40-support-experts-slider-item">
                            <div>
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723282410151-lWIq0-phot.png"
                                    alt="">
                            </div>
                            <p class="club40-support-experts-slider-item-name">Ирина Шутова</p>
                            <p class="club40-support-experts-slider-item-position">Коуч AFC</p>
                            <ul>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941303-GJlu3-Shie.svg"
                                        alt="">
                                    <p>6+ лет в коучинге и коммуникациях</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280940975-gG8Ko-2 Us.svg"
                                        alt="">
                                    <p>Помогла 1000+ клиентам получить офферы с релокацией и повышение</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941429-1DT3V-Work.svg"
                                        alt="">
                                    <p>Работала с сотрудниками PepsiCo, Amazon, VK, Pfizer</p>
                                </li>
                                <li class="careerum-flex-fsfs">
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723280941195-eXQNM-Loca.svg"
                                        alt="">
                                    <p>Россия</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="club40-support-info">
                    <div class="club40-support-mers">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Онлайн мероприятия</p>
                        </div>
                        <p class="club40-support-header">Интенсивы и Q&A-эфиры</p>
                        <p class="club40-support-content">На интенсивах глубоко погружаемся в ключевые карьерные темы и
                            помогаем каждому участнику достичь своей цели с поддержкой единомышленников и экспертов.</p>
                        <p class="club40-support-content">На Q&A-эфирах вместе с карьерными экспертами и другими участниками
                            сообщества решаем актуальные карьерные вопросы.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723487517601-J3a3P-Fram.svg"
                            alt="">
                    </div>
                    <div class="club40-support-netw">
                        <div class="club40-support-label careerum-flex-fsfs">
                            <p>Нетворкинг</p>
                        </div>
                        <p class="club40-support-header">Сильное сообщество профессионалов со всего мира</p>
                        <p class="club40-support-content">Доступ в сообщество из 1000+ профессионалов из 30+ стран, где вы
                            сможете завести полезные знакомства, найти поддержку среди участников с похожими проблемами и
                            целями и получить советы от тех, кто уже успешно прошел аналогичный путь.</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723554465492-akoKl-Grou.svg"
                            alt="">
                    </div>
                </div>
                <div class="club40-support-mats">
                    <div class="club40-support-mats-top">
                        <div>
                            <div class="club40-support-label careerum-flex-fsfs">
                                <p>Образовательные материалы</p>
                            </div>
                            <p class="club40-support-header">18 программ под любую карьерную задачу</p>
                            <ul>
                                <li>Построить карьеру за рубежом</li>
                                <li>Адаптироваться на новом рабочем месте</li>
                                <li>Регулярно расти в должности и доходе</li>
                                <li>Укрепить профессиональную самооценку</li>
                                <li>И другие темы, даже самые редкие</li>
                            </ul>
                        </div>
                        <div>
                            <div>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217563-Vnu5D-Note.svg"
                                            alt="">
                                        <p>В Сareerum больше 80 курсов<br> под любой запрос</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217283-YRFlS-Book.svg"
                                            alt="">
                                        <p>Методисты Careerum разделили<br> их по темам на программы и упаковали в понятные
                                            образовательные траектории</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723488217709-BpfbH-Trop.svg"
                                            alt="">
                                        <p>Вам осталось выбрать подходящую, а если возникнут вопросы — мы рядом</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="club40-support-mats-divider"></div>
                    <div class="club40-support-mats-slider-wrapper">
                        <p class="club40-support-mats-slider-header">Учитесь по программам<br> ведущих экспертов</p>
                        <div class="club40-support-mats-slider-items">
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285436842-nmhub-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Ольга Лермонтова</p>
                                <p class="club40-support-mats-slider-item-position">фаундер Careerum</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Занимала руководящие должности в Google и Microsoft</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Управляла бизнесом на 20+ рынках Африки и Европы</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>12 000+ клиентов из РФ, СНГ, Европы, Америки </p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Италия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437123-F7q7s-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Елена Резанова</p>
                                <p class="club40-support-mats-slider-item-position">карьерный стратег</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>9+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогает посмотреть на карьеру целостно и соотнести ее с целями бизнеса.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книг-бестселлеров «Никогда-нибудь» и «Это норм!»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Монако </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437246-oRSRS-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Sophie Vurpillot</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Испания</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437367-I19r8-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Анна Бабич</p>
                                <p class="club40-support-mats-slider-item-position">психолог</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Терапевт в сфере АСТ и эмоционально-фокусированной терапии</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Автор книги-бестселлера «Внутренняя опора»</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Спикер конференций: Psychologies, TeamLead, CodeFest</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Россия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437505-QfSia-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Montse Lorente</p>
                                <p class="club40-support-mats-slider-item-position">рекрутер и коуч</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>10+ лет опыта</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Помогла специалистам из 20+ стран раскрыть потенциал</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Сотрудничала с университетами Европы, США и Латинской Америки.</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Перу</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437627-rUPvo-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Санжар Ибрагимов</p>
                                <p class="club40-support-mats-slider-item-position">ментор</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Опыт в консалтинге в McKinsey & Company, PwC, World Bank Group</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>MBA в швейцарской бизнес-школе IMD</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в IMD International и Aalto Business School</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>Финляндия</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="club40-support-mats-slider-item">
                                <div>
                                    <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285437755-4zqkR-phot.png"
                                        alt="">
                                </div>
                                <p class="club40-support-mats-slider-item-name">Наталья Токарь</p>
                                <p class="club40-support-mats-slider-item-position">эксперт по коммуникации</p>
                                <ul>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>CEO проекта по обучению команд маркетинга, продаж, проджект-менеджеров и
                                            руководителей</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285254869-xXFal-Chec.svg"
                                            alt="">
                                        <p>Ментор в бизнес-акселераторе Чикаго</p>
                                    </li>
                                    <li class="careerum-flex-fsfs">
                                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723285306187-xC960-Loca.svg"
                                            alt="">
                                        <p>США</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-form', {
        label: 'Club 4 form',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-form">
            <div class="careerum-container-desc">
                <div class="careerum-flex-sbfs">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723287242177-1g7uZ-Vect.svg" alt="">
                        <h2>Начните знакомство <br>
                            с Careerum, это бесплатно!</h2>
                        <p>Заполните форму, а мы откроем вам доступ к образовательной платформе Careerum в демо-режиме. Увидите,
                            как работает платформа, и как мы поможем вам достичь целей</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723287284331-kmJYL-Grou.svg" alt="">
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных<br> в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Получить
                                    доступ</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-form-tablet', {
        label: 'Club 4 form (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-form-tablet">
            <div class="careerum-container-tablet">
                <div class="careerum-flex-sbst">
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723489041657-m4vpb-Vect.svg" alt="">
                        <h2>Начните знакомство <br>
                            с Careerum, это бесплатно!</h2>
                        <p>Откроем вам доступ к образовательной платформе Careerum в демо-режиме. Увидите, как она работает, и как мы поможем вам достичь целей</p>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723489074938-2IeWJ-Grou.svg" alt="">
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Получить
                                    доступ</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-form-mobile', {
        label: 'Club 4 form (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-form-mobile">
            <div class="careerum-container-mobile">
                <div>
                    <div>
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723489041657-m4vpb-Vect.svg"
                            alt="">
                        <h2>Начните знакомство <br>
                            с Careerum, это бесплатно!</h2>
                        <p>Откроем вам доступ к образовательной платформе Careerum в демо-режиме. Увидите, как она работает,
                            и как мы поможем вам достичь целей</p>
                    </div>
                    <div>
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm"
                                data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я даю согласие на обработку своих персональных данных в соответствии с <a
                                            href="/ru-ru/legal/privacy" target="_blank">Политикой конфиденциальности</a></p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>Получить
                                    доступ</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-popup-pay', {
        label: 'Club 4 popup pay',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-popup careerum-club40-popup-pay" id="">
            <div class="popup__close-button-wrapper">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="close-switch">
                    <rect width="32" height="32" rx="16" fill="#C2C2C2" />
                    <g clip-path="url(#clip0_7555_2802)">
                        <path d="M20.7979 20.6359L11.2059 11.083" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.1998 20.639L20.8018 11.0781" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7555_2802">
                            <rect width="12" height="12" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="club40-popup-pay-content">
                <div class="careerum-flex-sbfs">
                    <div class="club40-popup-pay-content-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457663947-ZJyDY-Unio.svg"
                            alt="">
                        <div class="club40-popup-pay-content-label m12 careerum-flex-fsfs">
                            <p>Подписка на 12 месяцев</p>
                        </div>
                        <div class="club40-popup-pay-content-label m6 careerum-flex-fsfs">
                            <p>Подписка на 6 месяцев</p>
                        </div>
                        <div class="club40-popup-pay-content-label m3 careerum-flex-fsfs">
                            <p>Подписка на 3 месяца</p>
                        </div>
                        <div class="club40-popup-pay-content-label m1 careerum-flex-fsfs">
                            <p>Подписка на 1 месяц</p>
                        </div>
                        <p>Тариф «Самостоятельный»</p>
                        <ul>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li rub">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Можно в рассрочку — первый платеж через 30 дней</p>
                            </li>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li eur">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Можно платить долями по персональному графику</p>
                            </li>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li single">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Подписка будет продлеваться автоматически за сутки до окончания</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>После оплаты вы получите письмо с инструкцией</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Продление подписки можно отменить в любой момент</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-popup-pay-content-right">
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form" name="devaRegistrationForm" data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я принимаю условия Оферты и даю согласие на обработку персональных данных
                                        в соответствии с Политикой конфиденциальности. Подписка будет автоматически
                                        продлеваться по аналогичным условиям за сутки до конца оплаченного периода</p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>К выбору способа оплаты</a>
                                <input type="hidden" hidden name="offerID" value="">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-popup-subscribe', {
        label: 'Club 4 popup sunscribe',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-popup careerum-club40-popup-pay careerum-club40-popup-subscribe" id="">
            <div class="popup__close-button-wrapper">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="close-switch">
                    <rect width="32" height="32" rx="16" fill="#C2C2C2" />
                    <g clip-path="url(#clip0_7555_2802)">
                        <path d="M20.7979 20.6359L11.2059 11.083" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.1998 20.639L20.8018 11.0781" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7555_2802">
                            <rect width="12" height="12" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="club40-popup-pay-content">
                <div class="careerum-flex-sbfs">
                    <div class="club40-popup-pay-content-left">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457663947-ZJyDY-Unio.svg"
                            alt="">
                        <div class="club40-popup-pay-content-label m12 careerum-flex-fsfs">
                            <p>Подписка на 12 месяцев</p>
                        </div>
                        <div class="club40-popup-pay-content-label m6 careerum-flex-fsfs">
                            <p>Подписка на 6 месяцев</p>
                        </div>
                        <div class="club40-popup-pay-content-label m3 careerum-flex-fsfs">
                            <p>Подписка на 3 месяца</p>
                        </div>
                        <div class="club40-popup-pay-content-label m1 careerum-flex-fsfs">
                            <p>Подписка на 1 месяц</p>
                        </div>
                        <p>Тариф «Самостоятельный»</p>
                        <ul>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li rub">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Можно в рассрочку — первый платеж через 30 дней</p>
                            </li>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li eur">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Можно платить долями по персональному графику</p>
                            </li>
                            <li class="careerum-flex-fsfs club40-popup-pay-content-top-li single">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Подписка будет продлеваться автоматически за сутки до окончания</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>После оплаты вы получите письмо с инструкцией</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723457666600-Zs2Dm-Fram.svg"
                                    class="popup-desctop-img" alt="">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723561372476-FqRod-Fram.svg"
                                    class="popup-mobile-img" alt="">
                                <p>Продление подписки можно отменить в любой момент</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-popup-pay-content-right">
                        <div class="careerum-registration-form-club40">
                            <form class="deva-form getcourse-email-signup" name="devaRegistrationForm" data-gjs-type="deva-registration-form">
                                <div class="careerum-club40-form-name">
                                    <input type="text" name="name" value="" required placeholder="Имя">
                                </div>
                                <div class="careerum-club40-form-email">
                                    <input type="text" name="email" value="" required placeholder="Почта">
                                </div>
                                <div class="careerum-club40-form-telegram">
                                    <input type="text" name="telegram" value="" required placeholder="Telegram">
                                </div>
                                <div class="careerum-club40-form-phone">
                                    <input type="tel" value="" name="tel" required class="itl-input">
                                </div>
                                <div class="careerum-club40-form-agree">
                                    <label>
                                        <input type="checkbox" name="agree">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"
                                                fill="none">
                                                <path
                                                    d="M1 2.84615L3.14168 6.70118C3.21595 6.83487 3.40669 6.83912 3.48685 6.70887L7 1"
                                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                    </label>
                                    <p>Я принимаю условия Оферты и даю согласие на обработку персональных данных
                                        в соответствии с Политикой конфиденциальности. Подписка будет автоматически
                                        продлеваться по аналогичным условиям за сутки до конца оплаченного периода</p>
                                </div>
                                <a href="#" class="submit-btn btn-color-main-bright-orange button-sent" disabled>К выбору способа оплаты</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('no-cabinet', {
        label: 'Club 4 popup no cabinet',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div id="thanks-no" class="careerum-popup careerum-club40-popup-cabinet no-cabinet">
            <div class="popup__close-button-wrapper">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="close-switch">
                    <rect width="32" height="32" rx="16" fill="#C2C2C2" />
                    <g clip-path="url(#clip0_7555_2802)">
                        <path d="M20.7979 20.6359L11.2059 11.083" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.1998 20.639L20.8018 11.0781" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7555_2802">
                            <rect width="12" height="12" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="careerum-club40-popup-cabinet-wrapper">
                <div>
                    <div>
                        <p class="careerum-club40-popup-cabinet-header">Теперь у вас есть личный кабинет!</p>
                        <p class="careerum-club40-popup-cabinet-content">Чтобы попасть туда, нужно всего лишь подтвердить
                            свой email.</p>
                        <p class="careerum-club40-popup-cabinet-content">Для этого нажмите кнопку ниже и следуйте
                            инструкции.</p>
                        <a class="close-switch btn-color-main-dark-orange cabinet-activate" href="#">Перейти в личный
                            кабинет</a>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('has-cabinet', {
        label: 'Club 4 popup has cabinet',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div id="thanks-yes" class="careerum-popup careerum-club40-popup-cabinet has-cabinet">
            <div class="popup__close-button-wrapper">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="close-switch">
                    <rect width="32" height="32" rx="16" fill="#C2C2C2" />
                    <g clip-path="url(#clip0_7555_2802)">
                        <path d="M20.7979 20.6359L11.2059 11.083" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.1998 20.639L20.8018 11.0781" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7555_2802">
                            <rect width="12" height="12" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="careerum-club40-popup-cabinet-wrapper">
                <div>
                    <div>
                        <p class="careerum-club40-popup-cabinet-header">Welcome back! Рады видеть вас снова</p>
                        <p class="careerum-club40-popup-cabinet-content">Вы уже зарегестрированы в Careerum. Чтобы войти
                            в личный кабинет, нажмите на кнопку ниже.</p>
                        <a class="close-switch btn-color-main-dark-orange cabinet-activate" href="#">Войти в личный
                            кабинет</a>
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-popup-thanks', {
        label: 'Club 4 popup thanks',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div id="thanks" class="careerum-popup careerum-club40-popup-thanks">
            <div class="popup__close-button-wrapper">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="close-switch">
                    <rect width="32" height="32" rx="16" fill="#C2C2C2" />
                    <g clip-path="url(#clip0_7555_2802)">
                        <path d="M20.7979 20.6359L11.2059 11.083" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M11.1998 20.639L20.8018 11.0781" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_7555_2802">
                            <rect width="12" height="12" fill="white" transform="translate(10 10)" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="careerum-club40-popup-thanks-wrapper">
                <div>
                    <div>
                        <p class="careerum-club40-popup-thanks-header">Спасибо, что<br> оставили заявку!</p>
                        <p class="careerum-club40-popup-thanks-content">Наши специалисты свяжутся с вами<br> в течение 24
                            часов</p>
                        <!-- <a class="close-switch btn-color-main-dark-orange" href="#">Войти в личный кабинет</a> -->
                    </div>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-banner1', {
        label: 'Club 4 banner1',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-banner1">
            <div class="careerum-container-desc">
                <div class="club40-banner1-top">
                    <div class="club40-banner1-top-top">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-top-bottom">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721933804-EK5Pa-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="club40-banner1-header">
                    <div class="club40-banner1-labels careerum-flex-fsfs">
                        <div class="club40-banner1-label blue-label">
                            <p>Интенсив по поиску работы</p>
                        </div>
                        <div class="club40-banner1-label">
                            <p>26 августа – 27 октября 2024</p>
                        </div>
                    </div>
                    <h2>Fast Track: быстрый путь к офферу мечты</h2>
                </div>
                <div class="club40-banner1-center careerum-flex-fsfs">
                    <div class="club40-banner1-about careerum-flex-fsfs">
                        <div class="club40-banner1-about-item">
                            <p>8 живых эфиров</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Много практики</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Эксперты международного класса</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Q&A-сессии</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Полезный нетворк и поддержка сообщества</p>
                        </div>
                    </div>
                    <div class="club40-banner1-advantages">
                        <div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717427906-NTzju-Fram.svg" alt="">
                                <p>Идеально подойдет для поиска как в РФ, так и за рубежом</p>
                            </div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717427906-NTzju-Fram.svg" alt="">
                                <p>Лучшие  лайфхаки для каждого этапа поиска работы</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-banner1-bottom">
                    <div class="club40-banner1-bottom-top">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718895738-qiN8B-Grou.svg" alt="">
                        <p class="club40-banner1-bottom-header">Интенсив подойдет вам, если:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Устали от долгого и безуспешного поиска работы</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Ваше резюме не читают<br> или игнорируют</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Нервничаете на интервью и не знаете, как себя вести</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Боитесь откликаться на «крутые» вакансии</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723717945036-7Fx3v-Unio.svg" alt="">
                                <p>Не знаете, какие<br> вакансии вам подойдут</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-bottom">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718896344-N4VBN-Grou.svg" alt="">
                        <p class="club40-banner1-bottom-header">Вы после интенсива:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Проанализируете рынок и поставите карьерные цели</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Построите стратегию поиска вакансий</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Напишете продающее резюме и сопроводительные письма</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Усилите ваш профиль в LinkedIn и научитесь использовать его в поиске</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Подготовитесь к собеседованиям и переговорам по офферу на русском или английском языках</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723718016945-FGdYu-Vect.svg" alt="">
                                <p>Сделаете свой поиск быстрым и эффективным</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-label">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721502311-LpuWO-Vect.svg" alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721501990-ar0dE-Grou.svg" alt="">
                        <p>Получите оффер в компании мечты на лучших условиях</p>
                    </div>
                </div>
                <div class="club40-banner1-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Принять участие</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-banner1-tablet', {
        label: 'Club 4 banner1 (tablet)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-banner1-tablet">
            <div class="careerum-container-tablet">
                <div class="club40-banner1-top">
                    <div class="club40-banner1-top-top">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-top-bottom">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="club40-banner1-header">
                    <div class="club40-banner1-labels careerum-flex-fsfs">
                        <div class="club40-banner1-label blue-label">
                            <p>Интенсив по поиску работы</p>
                        </div>
                        <div class="club40-banner1-label">
                            <p>26 августа – 27 октября 2024</p>
                        </div>
                    </div>
                    <h2>Fast Track: быстрый путь к офферу<br> мечты</h2>
                </div>
                <div class="club40-banner1-center careerum-flex-fsfs">
                    <div class="club40-banner1-about careerum-flex-fsfs">
                        <div class="club40-banner1-about-item">
                            <p>8 живых эфиров</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Много практики</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Полезный нетворк</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Q&A-сессии</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Поддержка сообщества</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Эксперты международного класса</p>
                        </div>
                    </div>
                    <div class="club40-banner1-advantages">
                        <div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724010636-Galjh-Grou.svg" alt="">
                                <p>Идеально подойдет для поиска как в РФ, так и за рубежом</p>
                            </div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724010636-Galjh-Grou.svg" alt="">
                                <p>Лучшие  лайфхаки для каждого этапа поиска работы</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="club40-banner1-bottom">
                    <div class="club40-banner1-bottom-top">
                        <p class="club40-banner1-bottom-header">Интенсив подойдет вам, если:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Устали от долгого и безуспешного поиска работы</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Ваше резюме не читают или игнорируют</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Нервничаете на интервью и не знаете, как себя вести</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Боитесь откликаться на «крутые» вакансии</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Не знаете, какие<br> вакансии вам подойдут</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-bottom">
                        <p class="club40-banner1-bottom-header">Вы после интенсива:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Проанализируете рынок и поставите карьерные цели</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Построите стратегию поиска вакансий</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Напишете продающее резюме и сопро-водительные письма</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Усилите ваш профиль в LinkedIn и научитесь использовать его в поиске</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Подготовитесь к собеседованиям на русском и английском</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Сделаете свой поиск эффективным и быстрым</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-label">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723721502311-LpuWO-Vect.svg" alt="">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723725080238-kKiTY-Grou.svg" alt="">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724888192-WsRoC-Vect.svg" alt="">
                            <p>Получите оффер в компании мечты на лучших условиях</p>
                        </div>
                    </div>
                </div>
                <div class="club40-banner1-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Принять участие</a>
                </div>
            </div>
        </div>`
    });

    blockManager.add('careerum-club40-banner1-mobile', {
        label: 'Club 4 banner1 (mobile)',  // can contain any HTML markup
        category: category, // name of component section
        attributes: { class: '' }, // for toolbox icon
        content: `
        <div class="careerum-club40-banner1-mobile">
            <div class="careerum-container-mobile">
                <div class="club40-banner1-top">
                    <div class="club40-banner1-top-top">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Главный ивент сезона найма</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-top-bottom">
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                            <li class="careerum-flex-fsc">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723723436409-aaIHx-Soft.svg" alt="">
                                <p>Оффер — уже этой осенью!</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="club40-banner1-header">
                    <div class="club40-banner1-labels">
                        <div class="club40-banner1-label blue-label">
                            <p>Интенсив по поиску работы</p>
                        </div>
                        <div class="club40-banner1-label">
                            <p>26 августа – 27 октября 2024</p>
                        </div>
                    </div>
                    <h2>Fast Track: быстрый путь к офферу мечты</h2>
                </div>
                <div class="club40-banner1-center">
                    <div class="club40-banner1-advantages">
                        <div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724010636-Galjh-Grou.svg" alt="">
                                <p>Идеально подойдет для поиска как в РФ, так и за рубежом</p>
                            </div>
                            <div class="club40-banner1-advantages-item careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724010636-Galjh-Grou.svg" alt="">
                                <p>Лучшие  лайфхаки для каждого этапа поиска работы</p>
                            </div>
                        </div>
                    </div>
                    <div class="club40-banner1-about careerum-flex-fsfs">
                        <div class="club40-banner1-about-item">
                            <p>8 живых эфиров</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Много практики</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Полезный нетворк</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Q&A-сессии</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Поддержка сообщества</p>
                        </div>
                        <div class="club40-banner1-about-item">
                            <p>Эксперты международного класса</p>
                        </div>
                    </div>
                </div>
                <div class="club40-banner1-bottom">
                    <div class="club40-banner1-bottom-top">
                        <p class="club40-banner1-bottom-header">Интенсив подойдет<br> вам, если:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Устали от долгого и безуспешного поиска работы</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Ваше резюме не читают или игнорируют</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Нервничаете на интервью и не знаете, как себя вести</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Боитесь откликаться на «крутые» вакансии</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Получаете отказы на разных этапах и не понимаете, почему</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724247891-MUKp2-Unio.svg" alt="">
                                <p>Не знаете, какие вакансии вам подойдут</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-bottom">
                        <p class="club40-banner1-bottom-header">Вы после интенсива:</p>
                        <ul class="careerum-flex-fsfs">
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Проанализируете рынок и поставите карьерные цели</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Построите стратегию поиска вакансий</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Напишете продающее резюме и сопроводительные письма</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Усилите ваш профиль в LinkedIn и научитесь использовать его в поиске</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Подготовитесь к собеседованиям<br>на русском или английском языках</p>
                            </li>
                            <li class="careerum-flex-fsfs">
                                <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724248356-hQhMB-Vect.svg" alt="">
                                <p>Сделаете свой поиск быстрым и эффективным</p>
                            </li>
                        </ul>
                    </div>
                    <div class="club40-banner1-bottom-label">
                        <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723725080238-kKiTY-Grou.svg" alt="">
                        <div class="careerum-flex-fsfs">
                            <img src="https://fra1.digitaloceanspaces.com/devabackenduploads/1723724888192-WsRoC-Vect.svg" alt="">
                            <p>Получите оффер в компании мечты на лучших условиях</p>
                        </div>
                    </div>
                </div>
                <div class="club40-banner1-link">
                    <a href="#buy" class="btn-color-main-dark-orange">Принять участие</a>
                </div>
            </div>
        </div>`
    });

}